import React, { useEffect, useMemo, useState } from 'react';
import styles from './Calls.module.scss';
import CrmNavBar from '../../components/CrmNavBar';
import TopBar from '../../components/TopBar';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { Link } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import SentimentSatisfiedAltRoundedIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';
import SentimentVerySatisfiedRoundedIcon from '@mui/icons-material/SentimentVerySatisfiedRounded';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import CallEndRoundedIcon from '@mui/icons-material/CallEndRounded';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import Select from 'react-select';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} color={props.value < 50 ? 'error' : 'success'} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary" sx={props.value < 50 ? { fontFamily: '"Geologica", sans-serif', color: '#D32F2F' } : { fontFamily: '"Geologica", sans-serif', color: '#2E7D32' }}>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function Calls() {
    const { t, i18n } = useTranslation();
    const [type, setType] = useState('all');
    const [calls, setCalls] = useState([]);
    const [filteredCalls, setFilteredCalls] = useState([]);
    const [company, setCompany] = useState({ value: 666, label: 'All' });
    const [companies, setCompanies] = useState([]);
    const [timeFrame, setTimeFrame] = useState('all');
    const [totalPages, setTotalPages] = useState(1);
    const [next , setNext] = useState(0)
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 18, //default page size
    });
    console.log(pagination)
    const [search, setSearch] = useState('');
    const fetchCalls = async () => {
        try {
            const response = await axios.get(`https://base.calltracker.ai/api/calls/company/test?timeframe=${timeFrame}&page=${pagination.pageIndex + 1}&search=${search?search:''}&company=${company.value !== 666 ? company.label : ''}&team_type=${type !== 'all' ? type : ''}`,  {
                headers: {
                    "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                }
            });
    
            const data = response.data;
    
            if (data) {
                setNext(data.count)
                setCalls(data.results);
                const totalRecords = data.count;
                const totalPages = Math.ceil(totalRecords / pagination.pageSize);
                setTotalPages(totalPages);
    
                // Ensure the current pageIndex is within the range of total pages
                if (pagination.pageIndex >= totalPages && totalPages > 0) {
                    setPagination((prev) => ({
                        ...prev,
                        pageIndex: totalPages - 1
                    }));
                } else if (pagination.pageIndex < 0) {
                    setPagination((prev) => ({
                        ...prev,
                        pageIndex: 0
                    }));
                }
            }
        } catch (e) {
            console.log(e);
            setPagination({
                pageIndex: 0,
                pageSize: 18, // default page size
            });
        }
    };


    useEffect(() => {
        fetchCalls();
    }, [type, company, timeFrame, pagination.pageIndex , search]);
    
    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const companyData = await axios.get(`https://base.calltracker.ai/api/company`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                });
                if (companyData) {
                    let companiesData = companyData.data;
                    let temp = companiesData.map(el => ({ value: el.id, label: el.name }));
                    temp.unshift({ value: 666, label: 'All' }); // Добавляем опцию "All"
                    setCompanies(temp);
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetchCompanies();
    }, []);


    function formatDuration(seconds) {
        const totalSeconds = Math.floor(seconds);
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    function formatMood(mood) {
        if (mood === 'NEUTRAL') {
            return <div style={{ width: '100%', display: 'flex', fontSize: '13px', alignItems: 'center', justifyContent: 'start', gap: 8, borderRadius: '6px', padding: '4px 8px', color: '#266EF4', backgroundColor: '#D4E2FD' }}><SentimentSatisfiedAltRoundedIcon />{t('CallsTableSentimentNeutral')}</div>;
        } else if (mood === 'POSITIVE') {
            return <div style={{ width: '100%', display: 'flex', fontSize: '13px', alignItems: 'center', justifyContent: 'start', gap: 8, borderRadius: '6px', padding: '4px 8px', color: '#007867', backgroundColor: '#C3DED5' }}><SentimentVerySatisfiedRoundedIcon />{t('CallsTableSentimentPositive')}</div>;
        } else if (mood === 'NEGATIVE') {
            return <div style={{ width: '100%', display: 'flex', fontSize: '13px', alignItems: 'center', justifyContent: 'start', gap: 8, borderRadius: '6px', padding: '4px 8px', color: '#D32F2F', backgroundColor: '#EEAFAF' }}><SentimentDissatisfiedRoundedIcon />{t('CallsTableSentimentNegative')}</div>;
        }
    }

    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 70,
            Cell: ({ row }) => (
                <Link to={`/call/${row.original.id}`} style={{ color: '#266EF4' }}>{row.original.id.slice(0, 4)}...{row.original.id.slice(-4)}</Link>
            )
        },
        {
            accessorKey: 'length',
            header: t('CallsTableLength'),
            size: 90,
            Cell: ({ row }) => (
                <div>{formatDuration(row.original.length)} мин</div>
            )
        },
        {
            accessorKey: 'agent_name',
            header: t('CallsTableAgent'),
            size: 210,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            accessorKey: 'created_at',
            header: t('CallsTableCallDate'),
            size: 135,
            Cell: ({ row }) => (
                <div>{moment(row.original.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
            )
        },
        {
            accessorKey: 'updated_at',
            header: t('CallsTableProcessDate'),
            size: 135,
            Cell: ({ row }) => (
                <div>{row.original.processed ? moment(row.original.updated_at).format('YYYY-MM-DD HH:mm:ss') :
                    <span style={{ padding: '4px 8px', backgroundColor: '#FFF1D6', color: '#B76E00', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px', borderRadius: '6px' }}><UpdateRoundedIcon />{t('CallsTableProcessing')}</span>}</div>
            )
        },
        {
            accessorKey: 'qa_percentage',
            header: t('CallsTableScript'),
            size: 115,
            Cell: ({ row }) => (
                <>
                    {row.original.processed ?
                        <div style={{ width: '100%' }}><LinearProgressWithLabel value={row.original.qa_percentage ? row.original.qa_percentage : 0} /></div>
                        : <span style={{ width: '100%', padding: '4px 8px', backgroundColor: '#FFF1D6', color: '#B76E00', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px', borderRadius: '6px' }}>
                            <UpdateRoundedIcon />{t('CallsTableProcessing')}
                        </span>}
                </>
            )
        },
        {
            accessorKey: 'toxic',
            header: t('CallsTableToxic'),
            size: 135,
            enableColumnActions: false,
            enableColumnFilter: false,
            Cell: ({ row }) => (
                <>
                    {row.original.processed ? <Tooltip
                        className={row.original.toxic ? styles.toxic : styles.notoxic}
                        style={row.original.toxic
                            ? { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', padding: '6px 12px', borderRadius: '8px', backgroundColor: '#EEAFAF', color: 'rgb(183, 29, 24)', gap: '10px', fontSize: '12px' }
                            : { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', padding: '6px 12px', borderRadius: '8px', backgroundColor: 'rgba(0, 120, 103,0.16)', color: 'rgb(0, 120, 103)', gap: '10px', fontSize: '12px' }}
                        arrow
                        title={<span style={{ fontFamily: '"Geologica", sans-serif' }}>{row.original.toxic ? 'В звонке присутствовали оскорбления со стороны агента' : 'Звонок прошел без оскорблений со стороны агента'}</span>}
                        placement='bottom'>{row.original.toxic ? <ThumbDownAltRoundedIcon /> : <ThumbUpRoundedIcon />} {row.original.toxic ? t('CallsTableToxicty') : t('CallsTableNoToxicty')}
                    </Tooltip> : <span style={{ width: '100%', padding: '4px 8px', backgroundColor: '#FFF1D6', color: '#B76E00', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px', borderRadius: '6px' }}>
                        <UpdateRoundedIcon />{t('CallsTableProcessing')}
                    </span>}
                </>
            )
        },
        {
            accessorKey: 'sentiment',
            header: t('CallsTableSentiment'),
            size: 134,
            Cell: ({ row }) => (
                row.original.processed ? formatMood(row.original.sentiment) : <span style={{ width: '100%', padding: '4px 8px', fontSize: '13px', backgroundColor: '#FFF1D6', color: '#B76E00', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px', borderRadius: '6px' }}>
                    <UpdateRoundedIcon />{t('CallsTableProcessing')}
                </span>
            )
        },
        {
            accessorKey: 'stage',
            header: t('CallsTableStage'),
            size: 180,
            Cell: ({ row }) => (
                <>
                    {row.original.processed ?
                        <span
                            style={{ width: '100%', padding: '4px 8px', backgroundColor: '#FFF1D6', color: '#B76E00', display: 'flex', fontSize: '13px', alignItems: 'center', justifyContent: 'start', gap: '8px', borderRadius: '6px' }}>
                            <CallEndRoundedIcon />{row.original.stage ? row.original.stage.slice(0, 1).toUpperCase() + row.original.stage.slice(1).toLowerCase() : ''}
                        </span>
                        : <span style={{ width: '100%', padding: '4px 8px', backgroundColor: '#FFF1D6', color: '#B76E00', display: 'flex', fontSize: '13px', alignItems: 'center', justifyContent: 'start', gap: '8px', borderRadius: '6px' }}>
                            <UpdateRoundedIcon />{t('CallsTableProcessing')}
                        </span>}
                </>
            )
        },
        {
            accessorKey: 'CompanyPresentation',
            header: '',
            size: 30,
            enableSorting: false,
            Cell: ({ row }) => (
                <Tooltip arrow
                    title={!row.original.presentation_company || row.original.presentation_company === 'UNKNOWN'
                        ? <span style={{ fontFamily: '"Geologica", sans-serif' }}>{t('SoloCallPresentationCompanyNo')}</span>
                        : <span style={{ fontFamily: '"Geologica", sans-serif' }}>{t('SoloCallPresentationCompany')} - {row.original.presentation_company}</span>}
                    placement='left'>
                    <ApartmentRoundedIcon sx={{ color: '#266EF4' }} />
                </Tooltip>
            )
        },
    ], [i18n.language]);

    const table = useMaterialReactTable({
        columns,
        data: calls,
        manualPagination: true,
        manualFiltering:true,
        state: { pagination,globalFilter: search },
        onGlobalFilterChange:setSearch,
        pageCount: totalPages,
        onPaginationChange: setPagination,
        initialState:{
            pagination
        },

        rowCount:next,
        autoResetPageIndex:false,
        paginationDisplayMode: 'pages',
        muiTableContainerProps: {
            sx: (theme) => ({
                height: "76vh"
            })
        },
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
        },
        muiTableBodyProps: {
            sx: (theme) => ({
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: '#fff',
                    borderBottom: '1px dashed rgba(145, 158, 171, 0.3)',
                },
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td': {
                    backgroundColor: '#fff',
                    borderBottom: '1px dashed rgba(145, 158, 171, 0.3)',
                },
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: '#fff',
                    borderBottom: '1px dashed rgba(145, 158, 171, 0.3)',
                },
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td': {
                    backgroundColor: '#fff',
                    borderBottom: '1px dashed rgba(145, 158, 171, 0.3)',
                },
            }),
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: '#fff',
            draggingBorderColor: '#637381',
        }),
        muiExpandButtonProps: ({ row }) => ({
            sx: {
                color: '#000'
            }
        }),
        muiTableBodyCellProps: ({ column }) => ({
            sx: {
                backgroundColor: '#fff',
                fontFamily: "'Geologica', sans-serif",
                fontSize: '14px',
                color: '#000',
                '& .MuiTableCell-root': {
                }
            },
        }),
        muiTableProps: ({ table }) => ({
            sx: {
                backgroundColor: 'grey',
                fontFamily: "'Geologica', sans-serif",
                fontSize: '14px',
            }
        }),
        muiTopToolbarProps: ({ table }) => ({
            sx: {
                backgroundColor: "#fff",
                fontFamily: "'Geologica', sans-serif",
                fontSize: '14px',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                '& .MuiButtonBase-root': {
                    color: '#637381'
                },
                '& .MuiSvgIcon-root': {
                    color: '#266EF4'
                },
                '& .MuiInputBase-root': {
                    borderColor: '#000'
                },
                '& .MuiInputBase-input': {
                    color: '#000',
                    fontFamily: "'Geologica', sans-serif",
                }
            }
        }),
        muiColumnActionsButtonProps: ({ row }) => ({
            sx: {
                color: '#000'
            }
        }),
        muiTableHeadCellProps: {
            sx: {
                fontWeight: 'bold',
                fontFamily: "'Geologica', sans-serif",
                fontSize: '14px',
                backgroundColor: '#fff',
                color: '#637381',
                '& .MuiSvgIcon-root': {
                    fill: '#000'
                },
                '& .MuiDivider-root': {
                },
                '& .MuiInputBase-input': {
                    color: '#000',
                    fontFamily: "'Geologica', sans-serif",
                }
            },
        },
        muiTableHeadProps: {
            sx: {
                '& .MuiTableCell-root': {
                    color: 'black'
                },
            }
        },
        muiPaginationProps: {
            showRowsPerPage: false,
            shape: 'rounded',
            sx: {
                '& .MuiButtonBase-root': {
                    color: '#000'
                }
            }
        },
        muiBottomToolbarProps: {
            sx: {
                backgroundColor: '#fff',
                color: '#000'
            },
        },
        muiFilterTextFieldProps: {
            sx: {
                color: '#000'
            }
        },
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        enableFullScreenToggle: false,
        localization: i18n.language === 'ru' ? {
            actions: 'Действия',
            and: 'и',
            cancel: 'Отменить',
            changeFilterMode: 'Изменить режим фильтра',
            changeSearchMode: 'Изменить режим поиска',
            clearFilter: 'Очистить фильтр',
            clearSearch: 'Очистить поиск',
            clearSelection: 'Очистить выбор',
            clearSort: 'Очистить сортировку',
            clickToCopy: 'Нажмите, чтобы скопировать',
            copy: 'Копировать',
            columnActions: 'Действие колонки',
            copiedToClipboard: 'Скопировано в буфер обмена',
            collapse: 'Свернуть',
            collapseAll: 'Свернуть все',
            dropToGroupBy: 'Добавить в группу к {column}',
            edit: 'Редактировать',
            expand: 'Раскрыть',
            expandAll: 'Раскрыть все',
            filterArrIncludes: 'Включить',
            filterArrIncludesAll: 'Влючить все',
            filterArrIncludesSome: 'Включить',
            filterBetween: 'Между',
            filterBetweenInclusive: 'Между включительно',
            filterByColumn: 'Отфильтровать по {column}',
            filterContains: 'Содержит',
            filterEmpty: 'Пустой',
            filterEndsWith: 'Оканчивается',
            filterEquals: 'Равны',
            filterEqualsString: 'Равны',
            filterFuzzy: 'Физзи',
            filterGreaterThan: 'Больше чем',
            filterGreaterThanOrEqualTo: 'Больше или равно',
            filterInNumberRange: 'Между',
            filterIncludesString: 'Содержит',
            filterIncludesStringSensitive: 'Содержит (регистрозависимый)',
            filterLessThan: 'Меньше чем',
            filterLessThanOrEqualTo: 'Меньше или равно',
            filterMode: 'Режим фильтра: {filterType}',
            filterNotEmpty: 'Не пустое',
            filterNotEquals: 'Не равны',
            filterStartsWith: 'Начинается с',
            filterWeakEquals: 'Равны',
            filteringByColumn: 'Фильтр по {column} - {filterType} {filterValue}',
            goToFirstPage: 'Перейти на первую страницу',
            goToLastPage: 'Перейти на последнюю страницу',
            goToNextPage: 'Перейти на следующую страницу',
            goToPreviousPage: 'Перейти на предыдущую страницу',
            grab: 'Grab',
            groupByColumn: 'Сгруппировать по {column}',
            groupedBy: 'Сгруппировать',
            hideAll: 'Скрыть всё',
            hideColumn: 'Скрыть {column} колонку',
            max: 'Макс',
            min: 'Мин',
            move: 'Двигать',
            noRecordsToDisplay: <Loading />,
            noResultsFound: 'Результатов не найдено',
            of: 'из',
            or: 'или',
            pin: 'Прикрепить',
            pinToLeft: 'Прикрепить слева',
            pinToRight: 'Прикрепить справа',
            resetColumnSize: 'Сбросить размер колонок',
            resetOrder: 'Сбросить порядок',
            rowActions: 'Действия строки',
            rowNumber: '#',
            rowNumbers: 'Количество строк',
            rowsPerPage: 'Строк на странице',
            save: 'Сохранить',
            search: 'Найти',
            selectedCountOfRowCountRowsSelected: '{selectedCount} из {rowCount} строк выбрано',
            select: 'Выбрать',
            showAll: 'Показать все',
            showAllColumns: 'Показать все колонки',
            showHideColumns: 'Показать/скрыть колонки',
            showHideFilters: 'Показать/скрыть фильтры',
            showHideSearch: 'Показать/скрыть поиск',
            sortByColumnAsc: 'Сортировать {column} по возрастанию',
            sortByColumnDesc: 'Сортировать {column} по убыванию',
            sortedByColumnAsc: 'Отсортирована по {column} возрастанию',
            sortedByColumnDesc: 'Отсортирована по {column} убыванию',
            thenBy: ', затем ',
            toggleDensity: 'Изменить плотность',
            toggleFullScreen: 'Включить полноэкранный режим',
            toggleSelectAll: 'Выбрать всё',
            toggleSelectRow: 'Переключить выбор строки',
            toggleVisibility: 'Изменить видимость',
            ungroupByColumn: 'Разгруппировать по {column}',
            unpin: 'Открепить',
            unpinAll: 'Открепить всё',
        } : {}
    });
    

    const timeFrames = [
        { value: 'all', label: 'all' },
        { value: 'day', label: 'day' },
        { value: 'week', label: 'week' },
        { value: 'month', label: 'month' },
    ];

    return (
        <div className={styles.page}>
            <CrmNavBar />
            <div className={styles.body}>
                <TopBar />
                <div className={styles.content}>
                    {(secureLocalStorage.getItem('roleName') === 'BrandManager'
                        || secureLocalStorage.getItem('roleName') === 'Couch'
                        || secureLocalStorage.getItem('roleName') === 'Admin') && (
                        <div className={styles.types}>
                            {secureLocalStorage.getItem('role') && (
                                <div className={styles.select}>
                                    <Select options={companies} value={company} onChange={e => setCompany({ value: e.value, label: e.label })} />
                                </div>
                            )}
                            <button style={type === 'all' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('all')}>{t('DashboardFilterAll')}</button>
                            <button style={type === 'RET' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('RET')}>RET</button>
                            <button style={type === 'SALE' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('SALE')}>SALE</button>
                            <Select options={timeFrames} value={{ value: timeFrame, label: timeFrame }} onChange={e => setTimeFrame(e.value)} />
                        </div>
                    )}
                    <MaterialReactTable table={table} />
                </div>
            </div>
        </div>
    );
}
