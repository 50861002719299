import React from 'react'
import { LineChart } from '@mui/x-charts/LineChart';
import { t } from 'i18next';

export default function ScoreChart({scoresWeek}) {
    console.log(scoresWeek)
    const sizing = {
        legend: { hidden: true },
    };
    const getLastSevenDays = () => {
        const days = [];
        for (let i = 6; i >= 0; i--) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            const formattedDate = date.toISOString().split('T')[0]; // Форматируем дату в "ГГГГ-ММ-ДД"
            days.push(formattedDate);
        }
        return days;
    };
    const xAxisData = getLastSevenDays();
  return (
    <LineChart
        sx={{
            fontFamily: '"Geologica",sans-serif',
            "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                strokeWidth: 0,
                fill: '#000',
                fontFamily: '"Geologica",sans-serif',
            },
            "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                fontFamily: "'Geologica',sans-serif",
            },
            "& .MuiChartsAxis-tick ": {
                stroke: '#000',
                strokeWidth: 0
            },
            '& .MuiLineElement-root': {
                strokeWidth: 4

            },
            // change bottom label styles
            "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                strokeWidth: "1",
                fill: '#000',
                fontFamily: "'Geologica',sans-serif",
                transform: 'scale(85%)',
            },
            '& .MuiAreaElement-series-green': {
                fill: "url(#SvgjsLinearGradient5114)",
            },
            '& .MuiAreaElement-series-red': {
                fill: "url('#SvgjsLinearGradient4848')",
            },
            "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                stroke: '#000',
                strokeWidth: 0
            },
            "& .MuiLineHighlightElement-root": {
                stroke: '#000',
                strokeWidth: 1
            },
            // leftAxis Line Styles
            "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                stroke: '#000',
                strokeWidth: 0
            },
            "& .MuiChartsAxis-left": {
                stroke: '#000',
                display: 'none'
            }
        }}
        xAxis={[{
            scaleType: 'point', data: xAxisData
        }]}
        series={[
            {
                id: 'green',
                data: scoresWeek,
                area: true,
                showMark: false,
                label: t('DashboardScoreChart'),
                color: '#00A76F',
                legend: { hidden: true }
            },
        ]}
        height={300}
        {...sizing}
    />
  )
}
