import React from 'react'
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export default function SimilarityChart({similarity}) {
  const {t} = useTranslation();
  const data = [
        { id: 1, value: similarity.low, label: t('DashboardScriptsBad'),color:"#FF5630" },
        { id: 0, value: similarity.medium, label: t('DashboardScriptsMiddle'),color:"#FFAB00" },
        { id: 2, value: similarity.decent, label: t('DashboardScriptsGood'),color:"#266EF4" },
        { id: 3, value: similarity.good, label: t('DashboardScriptsExcellent'),color:"#00A76F" },
    ];
    
    const sizing = {
        margin: { right: 5 },
        legend: { hidden: true },
      };
    const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);
    
    const getArcLabel = (params) => {
      const percent = params.value / TOTAL;
      if(isNaN(percent.toFixed(0))){
        return ''
      }
      return ``;
    };
    const StyledText = styled('text')(({ theme }) => ({
      fill:'#000',
      textAnchor: 'middle',
      dominantBaseline: 'central',
      fontSize: 28,
    }));
    function PieCenterLabel({ children }) {
      const { width, height, left, top } = useDrawingArea();
      return (
        <StyledText x={left + width / 2} y={top + height / 2}>
          {children}
        </StyledText>
      );
    }
    return (
        <PieChart
            series={[
                {
                    data,
                    innerRadius: 95,
                    paddingAngle: 3,
                    cornerRadius: 4,
                    arcLabel: (params) => { return '' },
                },
            ]}
            slotProps={{
                legend: {
                    hidden: true,
                    position: { vertical: 'top', horizontal: 'middle' },
                    padding: 0,
                },
            }}
            height={180}
            skipAnimation={true}
            {... sizing}
        >
        <PieCenterLabel>
            {similarity.good +similarity.decent+similarity.low+similarity.medium }
        </PieCenterLabel>
      </PieChart>
    );
}
