import React from 'react'
import WaveSurfer from 'wavesurfer.js'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeDownRoundedIcon from '@mui/icons-material/VolumeDownRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

const useWavesurfer = (containerRef, options) => {
    const [wavesurfer, setWavesurfer] = React.useState(null)
    
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    React.useEffect(() => {
      if (!containerRef.current) return
  
      const ws = WaveSurfer.create({
        ...options,
        container: containerRef.current,
        barWidth:4,
        barGap:2,
        height: 80,
        waveColor: '#d9dcff',
        progressColor: '#4353ff',
      });

      setWavesurfer(ws)
  
      return () => {
        ws.destroy()
      }
    }, [])
  
    return wavesurfer
  }
export const WaveSurferPlayer = (props) => {
    const containerRef = React.useRef()
    const [isPlaying, setIsPlaying] = React.useState(false)
    const [currentTime, setCurrentTime] = React.useState(0)
    const [playbackRate, setPlaybackRate] = React.useState(1)
    const wavesurfer = useWavesurfer(containerRef, props)
    const [volume, setVolume] = React.useState(50);

    const handleChange = (event, newValue) => {
        setVolume(newValue);
    };  
    React.useEffect(() => {
        if (wavesurfer) {
            wavesurfer.setVolume(volume / 100);
        }
    }, [volume, wavesurfer]);
    const onPlayClick = React.useCallback(() => {
      wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play()
    }, [wavesurfer])
    const onRateChange2 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(2)
        setPlaybackRate(2)
      }, [wavesurfer])
      const onRateChange15 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1.5)
        setPlaybackRate(1.5)
      }, [wavesurfer])
      const onRateChange1 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(1)
        setPlaybackRate(1)
      }, [wavesurfer])
      const onRateChange05 = React.useCallback(() => {
        wavesurfer.setPlaybackRate(0.5)
        setPlaybackRate(0.5)
      }, [wavesurfer])
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    React.useEffect(() => {
      if (!wavesurfer) return
  
      setCurrentTime(0)
      setIsPlaying(false)
  
      const subscriptions = [
        wavesurfer.on('play', () => setIsPlaying(true)),
        wavesurfer.on('pause', () => setIsPlaying(false)),
        wavesurfer.on('hover', () => console.log(currentTime)),
        wavesurfer.on('timeupdate', (currentTime) => setCurrentTime(currentTime)),
        wavesurfer.once('decode', () => {
          }),
          wavesurfer.on('mouseenter', (e) => {
            wavesurfer.params.progressColor = 'red';
            wavesurfer.drawBuffer();
          })
      ]

      return () => {
        subscriptions.forEach((unsub) => unsub())
      }
    }, [wavesurfer])

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = props.url;
        link.setAttribute('download', 'audio-file.wav'); // Замените на желаемое имя файла
        document.body.appendChild(link);
        link.click();
        link.remove();
    };
    console.log(props)
    return (
      <div style={{display:'flex' , alignItems:'center',justifyContent:'center' , flexDirection:'column',width:'100%'}}>
        <div id='waveform' ref={containerRef} style={{height: '80px' , width:'100%' }} />
        <div style={{display:"flex",flexDirection:"column", alignSelf:"center", justifySelf:"center", fontWeight:"bold" , marginTop:'20px'}}>
                            <div style={{display:"flex",flexDirection:"row", alignSelf:"center", justifySelf:"center", fontWeight:"bold", marginTop:40,gap:'10px'}}>
                            <button onClick={onPlayClick} className={!isPlaying?'btn btn-label-primary':'btn btn-label-primary'} 
                            style={{ width:50, height:30,display:'flex' , alignItems:'center',justifyContent:'center',borderRadius:'6px',zIndex:100,fontFamily:'"Geologica", sans-serif' , backgroundColor:'#EEF4FE',border:'none',color:'#266EF4'}}>
                                    {
                                    !isPlaying?
                                        <PlayArrowRoundedIcon/>
                                    :
                                        <PauseRoundedIcon/>
                                    }
                            </button>
                            <button onClick={onRateChange05} style={playbackRate === 0.5?
                            { width:50, height:30,borderRadius:'6px',zIndex:100,fontFamily:'"Geologica", sans-serif' , backgroundColor:'#EEF4FE',border:'1px solid #266EF4',color:'#266EF4'}
                            :{width:50, height:30,borderRadius:'6px',zIndex:100,fontFamily:'"Geologica", sans-serif' , backgroundColor:'#EEF4FE',border:'none',color:'#266EF4'}}>0.5x
                            </button>
                            <button onClick={onRateChange1} style={playbackRate === 1?
                            { width:50, height:30,borderRadius:'6px',zIndex:100,fontFamily:'"Geologica", sans-serif' , backgroundColor:'#EEF4FE',border:'1px solid #266EF4',color:'#266EF4'}
                            :{width:50, height:30,borderRadius:'6px',zIndex:100,fontFamily:'"Geologica", sans-serif' , backgroundColor:'#EEF4FE',border:'none',color:'#266EF4'}}> 1x
                            </button>
                            <button onClick={onRateChange15} style={playbackRate === 1.5?
                            { width:50, height:30,borderRadius:'6px',zIndex:100,fontFamily:'"Geologica", sans-serif' , backgroundColor:'#EEF4FE',border:'1px solid #266EF4',color:'#266EF4'}
                            :{width:50, height:30,borderRadius:'6px',zIndex:100,fontFamily:'"Geologica", sans-serif' , backgroundColor:'#EEF4FE',border:'none',color:'#266EF4'}}> 1.5x
                            </button>
                            <button onClick={onRateChange2} style={playbackRate === 2?
                            { width:50, height:30,borderRadius:'6px',zIndex:100,fontFamily:'"Geologica", sans-serif' , backgroundColor:'#EEF4FE',border:'1px solid #266EF4',color:'#266EF4'}
                            :{width:50, height:30,borderRadius:'6px',zIndex:100,fontFamily:'"Geologica", sans-serif' , backgroundColor:'#EEF4FE',border:'none',color:'#266EF4'}}> 2x
                            </button>
                            <button onClick={handleDownload} style={{width:50, height:30,display:'flex' , alignItems:'center',justifyContent:'center',borderRadius:'6px',zIndex:100,fontFamily:'"Geologica", sans-serif' , backgroundColor:'#EEF4FE',border:'none',color:'#266EF4'}}>
                                <DownloadRoundedIcon />
                            </button>
                            </div>
                            <div style={{width:'300px',marginTop:'20px'}}>
                            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                <VolumeDownRoundedIcon />
                                <Slider aria-label="Volume" size="small" sx={{color: '#266EF4'}} value={volume} onChange={handleChange} />
                                <VolumeUpRoundedIcon />
                            </Stack>

                            </div>
                        </div>
                        <div id="waveform-timeline"></div>
      </div>
    )
  }