import React from 'react';
import { Navigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

const ProtectedRoute = ({ children }) => {
  const token = secureLocalStorage.getItem('token');
  const isAuth = secureLocalStorage.getItem('isAuth') === true;

  if (!token || !isAuth) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
