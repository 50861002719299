import React, { useRef, useEffect } from 'react';

function Loading (height , width){
  const canvasRef = useRef(null);
  const animationIdRef = useRef(null);
  const timeRef = useRef(0);
  const heightsRef = useRef([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    
    const barWidth = 10;
    const barSpacing = 20;
    const barCount = Math.floor(10);
    const maxHeight = height / 2;

    // Генерация случайных высот для каждого столбика
    heightsRef.current = Array.from({ length: barCount }, () => Math.random() * maxHeight);

    const draw = () => {
      const time = timeRef.current;
      context.clearRect(0, 0, width, height);
      context.fillStyle = 'rgb(38, 110, 244)';

      for (let i = 0; i < barCount; i++) {
        const x = i * barSpacing;
        const heightOffset = Math.sin((i + time * 0.065) * 0.5) * heightsRef.current[i] + maxHeight;
        const barHeight = Math.abs(heightOffset - maxHeight);
        const y = (height - barHeight) / 2;

        context.beginPath();
        context.roundRect(x, y, barWidth, barHeight, 5); // Добавляем закругленные углы
        context.fill();
      }

      timeRef.current += 1;
      animationIdRef.current = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      cancelAnimationFrame(animationIdRef.current);
    };
  }, []);

  return (
    <canvas ref={canvasRef} width={width} height={height} />
  );
};

// Добавляем метод roundRect к контексту 2D
CanvasRenderingContext2D.prototype.roundRect = function (x, y, w, h, r) {
  if (w < 2 * r) r = w / 2;
  if (h < 2 * r) r = h / 2;
  this.beginPath();
  this.moveTo(x + r, y);
  this.arcTo(x + w, y, x + w, y + h, r);
  this.arcTo(x + w, y + h, x, y + h, r);
  this.arcTo(x, y + h, x, y, r);
  this.arcTo(x, y, x + w, y, r);
  this.closePath();
  return this;
};

export default Loading;