import React, { useEffect, useMemo, useState } from 'react';
import styles from './Admin.module.scss';
import CrmNavBar from '../../components/CrmNavBar';
import TopBar from '../../components/TopBar';
import secureLocalStorage from 'react-secure-storage';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import SentimentVerySatisfiedRoundedIcon from '@mui/icons-material/SentimentVerySatisfiedRounded';
import Loading from '../../components/Loading';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Swal from 'sweetalert2'
import moment from 'moment';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import SentimentNeutralRoundedIcon from '@mui/icons-material/SentimentNeutralRounded';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 2,
    outline:'none',
    display:'flex',
    alignItems:'center',
    justifyContent:'start',
    flexDirection:'column',
    gap:'20px',
    borderRadius:'12px'
  };
export default function Admin() {
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState([{ value: 1, label: 'Test Company' }])
    const [companyScript, setCompanyScript] = useState({ value: 1, label: 'Test Company' })
    const [companyWord, setCompanyWord] = useState({ value: 1, label: 'Test Company' })
    const [companyPres, setCompanyPres] = useState({ value: 1, label: 'Test Company' })
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [brandError, setBrandError] = useState('')
    const [loginError, setLoginError] = useState('')
    const [login, setLogin] = useState('')
    const [users, setUsers] = useState([])
    const [user, setUser] = useState({})
    const [brandName, setBrandName] = useState('')
    const [brandNameError, setBrandNameError] = useState('')
    const [scripts, setScripts] = useState([])
    const [newScript, setNewScript] = useState('')
    const [wordsListPositive , setWordsListPositive] = useState([]);
    const [wordsListNegative , setWordsListNegative] = useState([]);
    const [words, setWords] = useState('');
    const [wordsToAdd, setWordsToAdd] = useState([])
    const [roles, setRoles] = useState([])
    const [teams, setTeams] = useState([])
    const [editTeams, setEditTeams] = useState([])
    const [editTeam, setEditTeam] = useState({})
    const [editRole, setEditRole] = useState({})
    const [userRole, setUserRole] = useState(null)
    const [userTeam, setUserTeam] = useState(null)
    const [userTeamError, setUserTeamError] = useState('')
    const [userRoleError, setUserRoleError] = useState('')
    const [open, setOpen] = React.useState(false);
    const [userEditLogin , setUserEditLogin] = useState('')
    const [newPassword , setNewPassword] = useState('')
    const [companiesPres , setCompaniesPres] = useState({})
    const handleOpen = async (userData) =>{ 
        setOpen(true);
        setUser(userData);
        setUserEditLogin(userData.username)
        setEditRole({value:userData.role,label:userData.role_name})
        setEditTeam({value:userData.team, label:userData.team})
        if(userData?.companies?.length <= 1){
        try{
            const {data} = await axios.get(`https://base.calltracker.ai/api/teams/${userData.companies[0]}/`, {
                headers: {
                    "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                }
            })
            if(data){
                let temp =[]
                data.forEach(el=>{
                    temp.push({ value: el, label: el})
                })
                setEditTeams(temp)
            }
        }catch(e){
            console.log(e)
        }  
        }else{
            setEditTeams([])
        }
    };
    const handleClose = () => {setOpen(false);setNewPassword('')};
    useEffect(() => {
        const newWords = words
        .split(/[\n,]/)
        .map(word => word.trim())
        .filter(word => word !== '')
        setWordsToAdd(newWords)
      }, [words]);
    const CreateUser = async () => {
        if (!login) {
            setLoginError('Введите логин!');
            return;
        }
        if (company.length === 0) {
            setBrandError('Выберите бренд!');
            return;
        }
        if (!userRole) {
            setUserRoleError('Выберите роль!');
            return;
        }
        if (company.length <= 1 && !userTeam) {
            setUserTeamError('Выберите команду!');
            return;
        }
        if (!password) {
            setPasswordError('Сгенерируйте пароль!');
            return;
        }
        try {
            const companiesId = company.map(el=> el.value);
            const { data } = await axios.post('https://base.calltracker.ai/api/users/', company.length <= 1?{
                username: login,
                password: password,
                companies: companiesId,
                role:userRole.value,
                team: userTeam.value
            }:{
                username: login,
                password: password,
                companies: companiesId,
                role:userRole.value,
            }, {
                headers: {
                    "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                }
            });

            if (data) {
                const usersData = await axios.get('https://base.calltracker.ai/api/users', {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                });
                if (usersData) {
                    setUsers(usersData.data);
                }
                setLogin('');
                setPassword('');
                setCompany([]);
                setUserTeam(null);
                setUserRole(null);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const CreateBrand = async () => {
        if (!brandName) {
            setBrandNameError('Введите название бренда!')
            return
        }
        try {
            const { data } = await axios.post('https://base.calltracker.ai/api/company/', {
                name: brandName,
            }, {
                headers: {
                    "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                }
            })
            if (data) {
                const company = await axios.get(`https://base.calltracker.ai/api/company`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                })
                if (company) {
                    let companiesData = company.data
                    let temp = []
                    companiesData.forEach(el => {
                        temp.push({ value: el.id, label: el.name, billing: el.billing, script: el.script, id: el.id })
                    })
                    setCompanies(temp)

                }
                setBrandName('')
            }
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        const FetchData = async () => {
            try {
                const company = await axios.get(`https://base.calltracker.ai/api/company`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                })
                const rolesData = await axios.get(`https://base.calltracker.ai/api/roles`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                })
                if(rolesData){
                    let temp = []
                    rolesData.data.forEach(el=>{
                        temp.push({
                            value:el.id,
                            label: el.name
                        })
                    })
                    setRoles(temp)
                }
                if (company) {
                    let companiesData = company.data
                    let temp = []
                    console.log(companiesData)
                    companiesData.forEach(el => {
                        temp.push({ value: el.id,
                            label: el.name,
                            billing: el.billing,
                            script: el.script,
                            id: el.id, 
                            wordsPositive: el.check_words.filter(el=> el.positive === true),
                            wordsNegative: el.check_words.filter(el=> el.positive === false),
                            company_presentation : el.company_presentation
                        })
                    })
                    setCompanies(temp)
                    setWordsListNegative(company.data.filter(el => el.id === companyScript.value)[0].check_words.filter(el=> el.positive === false))
                    setWordsListPositive(company.data.filter(el => el.id === companyScript.value)[0].check_words.filter(el=> el.positive === true))
                    setScripts(company.data.filter(el => el.id === companyScript.value)[0].script)
                }
            } catch (e) {
                console.log(e)
            }
        }
        FetchData()
    }, [])
    
    function generateRandomPassword(length) {
        setPasswordError('')
        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let password1 = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password1 += charset.charAt(randomIndex);
        }
        setPassword(password1)
    }
    const handleRemoveWordListNegative = async(wordToRemove) => {
        const newWords = wordsListNegative.filter(word => word !== wordToRemove);
        console.log(newWords)
        try{
            const {data} = await axios.patch(`https://base.calltracker.ai/api/company/${companyWord.value}/`,{
                check_words:wordsListPositive.concat(newWords),
              },
              {
                headers: {
                  Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                },
              })
              if(data){
                const updateCompany = companies.filter(elem=> elem.value !== data.id) 
                setCompanies([...updateCompany , { value: data.id,
                    label: data.name,
                    billing: data.billing,
                    script: data.script,
                    id: data.id, 
                    wordsPositive: data.check_words.filter(el=> el.positive === true),
                    wordsNegative: data.check_words.filter(el=> el.positive === false)}].sort((a,b)=> a.id - b.id ))
                setWordsListPositive(data.check_words.filter(el=> el.positive === true))
                setWordsListNegative(data.check_words.filter(el=> el.positive === false))
              }
        }catch(e){
            console.log(e)
        }
    };
    const handleRemoveWordListPositive = async(wordToRemove) => {
        const newWords = wordsListPositive.filter(word => word !== wordToRemove);
        try{
            const {data} = await axios.patch(`https://base.calltracker.ai/api/company/${companyWord.value}/`,{
                check_words:newWords.concat(wordsListNegative),
              },
              {
                headers: {
                  Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                },
              })
              if(data){
                console.log(companyWord)
                const updateCompany = companies.filter(elem=> elem.value !== data.id) 
                setCompanies([...updateCompany , { value: data.id,
                    label: data.name,
                    billing: data.billing,
                    script: data.script,
                    id: data.id, 
                    wordsPositive: data.check_words.filter(el=> el.positive === true),
                    wordsNegative: data.check_words.filter(el=> el.positive === false)}].sort((a,b)=> a.id - b.id ))
                setWordsListPositive(data.check_words.filter(el=> el.positive === true))
                setWordsListNegative(data.check_words.filter(el=> el.positive === false))
              }
        }catch(e){
            console.log(e)
        }
    };  
    const handleRemoveScript = async (scriptToRemove) => {
        const newScripts = scripts.filter(script => script !== scriptToRemove);
        try {
            const { data } = await axios.patch(`https://base.calltracker.ai/api/company/${companyScript.value}/`, {
                script: newScripts,
            },
                {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
            if (data) {
                const company = await axios.get(`https://base.calltracker.ai/api/company`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                })
                if (company) {
                    let companiesData = company.data
                    let temp = []
                    companiesData.forEach(el => {
                        temp.push({ value: el.id, label: el.name, billing: el.billing, script: el.script, id: el.id })
                    })
                    setCompanies(temp)
                    setScripts(company.data.filter(el => el.id === companyScript.value)[0].script)
                }
            }
        } catch (e) {
            console.log(e)
        }
    };
    const AddScript = async () => {
        if (!newScript) return
        try {
            let modifiedScriptInput = newScript.trim();
            if (modifiedScriptInput.length > 0) {
                const lastChar = modifiedScriptInput.slice(-1);
                if (lastChar === '.') {
                    modifiedScriptInput = modifiedScriptInput.slice(0, -1) + '?';
                } else if (lastChar === '!') {
                    modifiedScriptInput = modifiedScriptInput.slice(0, -1) + '?';
                } else if (lastChar !== '?') {
                    modifiedScriptInput += '?';
                }
            }
            const newScripts = [...scripts, modifiedScriptInput];
            const { data } = await axios.patch(`https://base.calltracker.ai/api/company/${companyScript.value}/`, {
                script: newScripts,
            },
                {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
            if (data) {
                const company = await axios.get(`https://base.calltracker.ai/api/company`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                })
                if (company) {
                    let companiesData = company.data
                    let temp = []
                    companiesData.forEach(el => {
                        temp.push({ value: el.id, label: el.name, billing: el.billing, script: el.script, id: el.id })
                    })
                    setCompanies(temp)
                    setScripts(company.data.filter(el => el.id === companyScript.value)[0].script)
                }
                setNewScript('')
            }
        } catch (e) {
            console.log(e)
        }
    }
    const AddPositiveWords = async ()=>{
        const newWords = wordsToAdd.map(word => ({ word, positive: true }));
        try{
            const {data} = await axios.patch(`https://base.calltracker.ai/api/company/${companyWord.value}/`,{
                check_words:newWords.concat(wordsListPositive).concat(wordsListNegative),
              },
              {
                headers: {
                  Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                },
              })
              if(data){
                const updateCompany = companies.filter(elem=> elem.value !== data.id) 
                setCompanies([...updateCompany , { value: data.id,
                    label: data.name,
                    billing: data.billing,
                    script: data.script,
                    id: data.id, 
                    wordsPositive: data.check_words.filter(el=> el.positive === true),
                    wordsNegative: data.check_words.filter(el=> el.positive === false)}].sort((a,b)=> a.id - b.id ))
                setWordsListPositive(data.check_words.filter(el=> el.positive === true))
                setWordsListNegative(data.check_words.filter(el=> el.positive === false))
                setWords('')
                setWordsToAdd([])
              }
        }catch(e){
            console.log(e)
        }
    }
    const AddNegativeWords = async ()=>{
        const newWords = wordsToAdd.map(word => ({ word, positive: false }));
        try{
            const {data} = await axios.patch(`https://base.calltracker.ai/api/company/${companyWord.value}/`,{
                check_words:newWords.concat(wordsListNegative).concat(wordsListPositive),
              },
              {
                headers: {
                  Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                },
              })
              if(data){
                const updateCompany = companies.filter(elem=> elem.value !== data.id) 
                setCompanies([...updateCompany , { value: data.id,
                    label: data.name,
                    billing: data.billing,
                    script: data.script,
                    id: data.id, 
                    wordsPositive: data.check_words.filter(el=> el.positive === true),
                    wordsNegative: data.check_words.filter(el=> el.positive === false)}].sort((a,b)=> a.id - b.id ))
                setWordsListPositive(data.check_words.filter(el=> el.positive === true))
                setWordsListNegative(data.check_words.filter(el=> el.positive === false))
                setWords('')
                setWordsToAdd([])
              }
        }catch(e){
            console.log(e)
        }
    }
    useEffect(() => {

        if (companies.length > 0) {
            setScripts(companies.filter(el => el.id === companyScript.value)[0]?.script)
        } else {
            setScripts([])
        }
    }, [companyScript, companies])

    useEffect(() => {

        if (companies.length > 0) {
            console.log(companies?.filter(el => el.id === companyWord.value)[0])
            setWordsListNegative(companies?.filter(el => el.id === companyWord.value)[0].wordsNegative)
            setWordsListPositive(companies?.filter(el => el.id === companyWord.value)[0].wordsPositive)
        } else {
            setWordsListNegative([])
            setWordsListPositive([])
        }
    }, [companyWord, companies])

    useEffect(() => {

        if (companies.length > 0) {
            console.log(companies?.filter(el => el.id === companyPres.value)[0].company_presentation)
            setCompaniesPres(companies?.filter(el => el.id === companyPres.value)[0].company_presentation)
        } else {
            setCompaniesPres([])
        }
    }, [companyPres, companies])
    console.log(companiesPres)
    useEffect(()=>{
        const fetchUsers = async ()=>{
            try{
                const {data} = await axios.get(`https://base.calltracker.ai/api/users/`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                })
                if(data){
                    setUsers(data)
                }
            }catch(e){
                console.log(e)
            }
        }   
        fetchUsers()
    },[])

    const columns = useMemo(() => [
        {
            accessorKey: 'username',
            header: 'Логин',
            size:110, 
            editable: false,
        },
        {
            accessorKey: 'role_name',
            header: 'Роль',
            size:120,
        },
        {
            accessorKey: 'company_name',
            header: 'Бренд',
            size:100,
            Cell :({row})=>(
                <span>
                    {companies.length > 0? companies.filter(elem=> row.original.companies.includes(elem.value)).map(el=> <div key={el.value}>{el.label}</div>):null}
                </span>
            )
        },
        {
            accessorKey: 'last_login',
            header: 'Авторизация',
            size:160,
            Cell :({row})=>(
                <span >
                    {row.original.last_login? moment(row.original.last_login).format('YYYY-MM-DD HH:mm:ss'):''}
                </span>
            )
        },
        {
            accessorKey: 'actions',
            header: 'Действия',
            size:130,
            Cell :({row})=>(
                <div style={{display:'flex' , alignItems:'start',justifyContent:'start',flexDirection:'column',gap:'8px'}}>
                    <button onClick={()=>handleOpen(row.original)} style={{display:'flex' , alignItems:'center',justifyContent:'center',border:'none',backgroundColor:'#FFF1D6',color:'#B76E00',fontFamily:'"Geologica", sans-serif',width:'100%',borderRadius:'8px',gap:'5px',padding:'4px 8px',fontWeight:'500',cursor:'pointer'}}>Изменить <EditRoundedIcon/></button>
                    <button onClick={()=> DeleteUser(row.original.id)} style={{display:'flex' , alignItems:'center',justifyContent:'center',border:'none',backgroundColor:'#EEAFAF',color:'#B71D18',fontFamily:'"Geologica", sans-serif',width:'100%',borderRadius:'8px',gap:'5px',padding:'4px 8px',fontWeight:'500',cursor:'pointer'}}>Удалить <DeleteForeverRoundedIcon/></button>
                </div>
            )
        },
    ], [companies]);

    const DeleteUser = async (userId)=>{
        Swal.fire({
            background: '#fff',
            title: "Вы уверенны?",
            color: '#000',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: 'rgba(255, 48, 48, 0.16)',
            cancelButtonColor: "#d33",
            cancelButtonText: "Отмена",
            confirmButtonText: "Удалить",
            buttonsStyling: false,
            customClass: {
                title: '',
                text: 'text',
                cancelButton: 'Custom_Cancel_LightMode',
                confirmButton: 'Custom_Confirm_LightMode',
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                    const {data} = await axios.delete(`https://base.calltracker.ai/api/users/${userId}/`, {
                        headers: {
                            "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                        }
                    })
                        const usersData = await axios.get(`https://base.calltracker.ai/api/users/`, {
                          headers: {
                              "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                          }
                      })
                      if(usersData){
                          setUsers(usersData.data)
                        Swal.fire({
                            title: "Удаленно!",
                            text: "Пользователь был успешно удалён",
                            icon: "success"
                          });
                    }
                }catch(e){
                    console.log(e)
                }
            }
          });
    }
    const table = useMaterialReactTable({
        columns,
        data : users,
        initialState: { density: 'spacious', pagination: {pageSize:18} },
        muiTableContainerProps: {
          sx: (theme) => ({
            height:"29vh"
          })
        },
        defaultColumn: {
          maxSize: 400,
          minSize: 80,
          size: 160,
        }, 
        muiTableBodyProps: {
          sx: (theme) => ({
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
              {
                backgroundColor:'#fff',
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
    
              },
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
              {
                backgroundColor: '#fff',
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
    
              },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
              {
                backgroundColor: '#fff',
                
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
              },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
              {
                backgroundColor: '#fff',
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
              },
          }),
        },
        mrtTheme: (theme) => ({
          baseBackgroundColor: '#fff',
        }),
        muiExpandButtonProps:({row}) =>({
            sx:{
            color:'#000'
        
            }
        }),
        muiTableBodyCellProps: ({ column }) => ({
            sx: {
              backgroundColor:'#fff',
              fontFamily:"'Geologica', sans-serif",
              fontSize: '14px',
              paddingLeft:'5px',
              color:'#000',
              '& .MuiTableCell-root':{
              }
            },
          }),
        muiTableProps: ({table})=>({
        sx:{
            backgroundColor:'grey',
            fontFamily:"'Geologica', sans-serif",
            fontSize: '14px',
        }
        }),
        muiTopToolbarProps: ({table})=>({
        sx:{
            backgroundColor:"#fff",
            fontFamily:"'Geologica', sans-serif",
            fontSize: '14px',
            borderTopLeftRadius:'5px',
            borderTopRightRadius:'5px',
            '& .MuiButtonBase-root':{
            color:'#637381'
            },
            '& .MuiSvgIcon-root':{
            color:'#000'

            },
            '& .MuiInputBase-root':{
            borderColor:'#000'

            },
            '& .MuiInputBase-input':{
            color:'#000',
            fontFamily:"'Geologica', sans-serif",
            }
        }
        }),
        muiColumnActionsButtonProps: ({row})=>({
        sx:{
            color:'#000'
        }
        }),
        muiTableHeadCellProps: {
        sx: {
            fontWeight: 'bold',
            fontFamily:"'Geologica', sans-serif",
            fontSize: '14px',
            backgroundColor:'#fff',
            color:'#637381',
            paddingLeft:'5px',
            '& .MuiSvgIcon-root':{
            fill:'#000'
            },
            '& .MuiDivider-root':{
            },
            '& .MuiInputBase-input':{
            color:'#000',
            fontFamily:"'Geologica', sans-serif",
            }
        },
        },
          muiTableHeadProps:{
            sx:{
              '& .MuiTableCell-root':{
                color:'black'
              },
            }
          },
          muiPaginationProps: {
            showRowsPerPage: false,
            shape: 'rounded',
            sx:{
              '& .MuiButtonBase-root':{
                color:'#000'
              }
            }
          },
          muiBottomToolbarProps:{
            sx:{
              backgroundColor:'#fff',
              color:'#000'
    
            },
          },
          muiFilterTextFieldProps:{
            sx:{
              color:'#000'
            }
          },
          paginationDisplayMode: 'pages',
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        enableFullScreenToggle: false,
        localization:{
          actions: 'Действия',
          and: 'и',
          cancel: 'Отменить',
          changeFilterMode: 'Изменить режим фильтра',
          changeSearchMode: 'Изменить режим поиска',
          clearFilter: 'Очистить фильтр',
          clearSearch: 'Очистить поиск',
          clearSelection: 'Очистить выбор',
          clearSort: 'Очистить сортировку',
          clickToCopy: 'Нажмите, чтобы скопировать',
          copy: 'Копировать',
          columnActions: 'Действие колонки',
          copiedToClipboard: 'Скопировано в буфер обмена',
          collapse: 'Свернуть',
          collapseAll: 'Свернуть все',
          dropToGroupBy: 'Добавить в группу к {column}',
          edit: 'Редактировать',
          expand: 'Раскрыть',
          expandAll: 'Раскрыть все',
          filterArrIncludes: 'Включить',
          filterArrIncludesAll: 'Влючить все',
          filterArrIncludesSome: 'Включить',
          filterBetween: 'Между',
          filterBetweenInclusive: 'Между включительно',
          filterByColumn: 'Отфильтровать по {column}',
          filterContains: 'Содержит',
          filterEmpty: 'Пустой',
          filterEndsWith: 'Оканчивается',
          filterEquals: 'Равны',
          filterEqualsString: 'Равны',
          filterFuzzy: 'Физзи',
          filterGreaterThan: 'Больше чем',
          filterGreaterThanOrEqualTo: 'Больше или равно',
          filterInNumberRange: 'Между',
          filterIncludesString: 'Содержит',
          filterIncludesStringSensitive: 'Содержит (регистрозависимый)',
          filterLessThan: 'Меньше чем',
          filterLessThanOrEqualTo: 'Меньше или равно',
          filterMode: 'Режим фильтра: {filterType}',
          filterNotEmpty: 'Не пустое',
          filterNotEquals: 'Не равны',
          filterStartsWith: 'Начинается с',
          filterWeakEquals: 'Равны',
          filteringByColumn: 'Фильтр по {column} - {filterType} {filterValue}',
          goToFirstPage: 'Перейти на первую страницу',
          goToLastPage: 'Перейти на последнюю страницу',
          goToNextPage: 'Перейти на следующую страницу',
          goToPreviousPage: 'Перейти на предыдущую страницу',
          grab: 'Grab',
          groupByColumn: 'Сгруппировать по {column}',
          groupedBy: 'Сгруппировать',
          hideAll: 'Скрыть всё',
          hideColumn: 'Скрыть {column} колонку',
          max: 'Макс',
          min: 'Мин',
          move: 'Двигать',
          noRecordsToDisplay: <Loading/>,
          noResultsFound: 'Результатов не найдено',
          of: 'из',
          or: 'или',
          pin: 'Прикрепить',
          pinToLeft: 'Прикрепить слева',
          pinToRight: 'Прикрепить справа',
          resetColumnSize: 'Сбросить размер колонок',
          resetOrder: 'Сбросить порядок',
          rowActions: 'Действия строки',
          rowNumber: '#',
          rowNumbers: 'Количество строк',
          rowsPerPage: 'Строк на странице',
          save: 'Сохранить',
          search: 'Найти',
          selectedCountOfRowCountRowsSelected: '{selectedCount} из {rowCount} строк выбрано',
          select: 'Выбрать',
          showAll: 'Показать все',
          showAllColumns: 'Показать все колонки',
          showHideColumns: 'Показать/скрыть колонки',
          showHideFilters: 'Показать/скрыть фильтры',
          showHideSearch: 'Показать/скрыть поиск',
          sortByColumnAsc: 'Сортировать {column} по возрастанию',
          sortByColumnDesc: 'Сортировать {column} по убыванию',
          sortedByColumnAsc: 'Отсортирована по {column} возрастанию',
          sortedByColumnDesc: 'Отсортирована по {column} убыванию',
          thenBy: ', затем ',
          toggleDensity: 'Изменить плотность',
          toggleFullScreen: 'Включить полноэкранный режим',
          toggleSelectAll: 'Выбрать всё',
          toggleSelectRow: 'Переключить выбор строки',
          toggleVisibility: 'Изменить видимость',
          ungroupByColumn: 'Разгруппировать по {column}',
          unpin: 'Открепить',
          unpinAll: 'Открепить всё',
        }
    
    });
    useEffect(()=>{
        const fetchTeams = async ()=>{
            setUserTeam({})
            try{
                const {data} = await axios.get(`https://base.calltracker.ai/api/teams/${company[0].value}/`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                })
                if(data){
                    let temp =[]
                    data.forEach(el=>{
                        temp.push({ value: el, label: el})
                    })
                    setTeams(temp)
                }
            }catch(e){
                console.log(e)
            }
        }
        if(company[0]?.value){
            fetchTeams()
        }
    },[company])
    const EditUser = async ()=>{
        try{
            const {data} = await axios.patch(`https://base.calltracker.ai/api/users/${user.id}/`,newPassword?{
                username: userEditLogin,
                role:editRole.value,
                password: newPassword,
                team:editTeam.value
              }:{
                username: userEditLogin,
                role:editRole.value,
                team:editTeam.value
              },
              {
                headers: {
                  Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                },
              })
              if(data){
                const usersData = await axios.get(`https://base.calltracker.ai/api/users/`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                })
                if(usersData){
                    setUsers(usersData.data)
                    handleClose()
                }
              }
        }catch(e){
            console.log(e)
        }
    }
    if (!secureLocalStorage.getItem('role')) {
        return <Navigate to={'/dashboard'} />
    } else
        return (
            <div className={styles.page}>
                <CrmNavBar />
                <div className={styles.body}>
                    <TopBar />
                    <div className={styles.content}>
                        <div className={styles.topBlock}>
                            <div className={styles.AddUser}>
                                <h3>Создать пользователя</h3>
                                <input placeholder='Логин' onChange={e => { setLogin(e.target.value); setLoginError('') }} />
                                {loginError ? <p style={{ fontSize: '12px', color: 'red', margin: '0px', textAlign: 'start', width: '100%' }}>{loginError}</p> : ''}
                                <div className={styles.select}>
                                    <Select options={companies} isMulti value={company} placeholder={'Выберите бренд'} styles={
                                        {
                                            control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', marginRight: '2px' }),
                                            placeholder: (styles) => ({ ...styles, textAlign: 'start' }),
                                            option: (styles) => ({ ...styles, textAlign: 'start' }),
                                            singleValue: (styles) => ({ ...styles, textAlign: 'start' }),
                                            input: (styles) => ({ ...styles, textAlign: 'start', padding: '0px', margin: '0px'}),
                                        }
                                    }
                                        onChange={e => { setCompany(e); setBrandError('') }} />
                                </div>
                                {brandError ? <p style={{ fontSize: '12px', color: 'red', margin: '0px', textAlign: 'start', width: '100%' }}>{brandError}</p> : ''}
                                <div className={styles.select}>
                                    <Select options={roles} value={userRole?userRole:null} placeholder={'Выберите роль'} styles={
                                        {
                                            control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', marginRight: '2px',height:'35px' }),
                                            placeholder: (styles) => ({ ...styles, textAlign: 'start' }),
                                            option: (styles) => ({ ...styles, textAlign: 'start' }),
                                            singleValue: (styles) => ({ ...styles, textAlign: 'start' }),
                                            input: (styles) => ({ ...styles, textAlign: 'start', padding: '0px', margin: '0px',height:'32px' }),
                                        }
                                    }
                                        onChange={e => { setUserRole({ value: e.value, label: e.label }); setUserRoleError('') }} />
                                </div>
                                {userRoleError ? <p style={{ fontSize: '12px', color: 'red', margin: '0px', textAlign: 'start', width: '100%' }}>{userRoleError}</p> : ''}
                                {
                                    company.length <=1?
                                    <div className={styles.select}>
                                        <Select options={teams} value={userTeam?userTeam:null} placeholder={'Выберите команду'} styles={
                                            {
                                                control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', marginRight: '2px',height:'35px' }),
                                                placeholder: (styles) => ({ ...styles, textAlign: 'start' }),
                                                option: (styles) => ({ ...styles, textAlign: 'start' }),
                                                singleValue: (styles) => ({ ...styles, textAlign: 'start' }),
                                                input: (styles) => ({ ...styles, textAlign: 'start', padding: '0px', margin: '0px',height:'32px' }),
                                            }
                                        }
                                            onChange={e => { setUserTeam({ value: e.value, label: e.label }); setUserTeamError('') }} />
                                    </div>:''
                                }
                                {userTeamError ? <p style={{ fontSize: '12px', color: 'red', margin: '0px', textAlign: 'start', width: '100%' }}>{userTeamError}</p> : ''}
                                <div className={styles.password}>
                                    <h4>Сгенерировать пароль</h4>
                                    <button onClick={() => generateRandomPassword(10)}>Сгенерировать</button>
                                </div>
                                {passwordError ? <p style={{ fontSize: '12px', color: 'red', margin: '0px', textAlign: 'start', width: '100%' }}>{passwordError}</p> : ''}
                                <p>{password}</p>
                                <button className={styles.create} onClick={CreateUser}>Создать</button>
                            </div>
                            <div className={styles.AddBrand}>
                                <h3>Создать бренд</h3>
                                <input placeholder='Название бренда' onChange={e => { setBrandName(e.target.value); setBrandNameError('') }} />
                                {brandNameError ? <p style={{ fontSize: '12px', color: 'red', margin: '0px', textAlign: 'start', width: '100%' }}>{brandNameError}</p> : ''}
                                <div className={styles.brandList}>
                                    {companies.map(el => <div className={styles.brand}>{el.label} <PaidRoundedIcon />{el.billing? +el.billing : 0 }$</div>)}
                                </div>
                                <button className={styles.create} onClick={CreateBrand}>Создать</button>

                            </div>
                            <div className={styles.AddScript}>
                                <h3>Добавить скрипт</h3>
                                <div className={styles.scriptForm}>
                                    <div className={styles.companySelect}>
                                        <Select options={companies} placeholder={'Выберите бренд'} value={companyScript} styles={
                                            {
                                                control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', padding: '0px', margin: '0px' }),
                                                placeholder: (styles) => ({ ...styles, textAlign: 'start', padding: '0px' }),
                                                option: (styles) => ({ ...styles, textAlign: 'start' }),
                                                singleValue: (styles) => ({ ...styles, textAlign: 'start', padding: '0px' }),
                                                input: (styles) => ({ ...styles, textAlign: 'start', padding: '0px', margin: '0px' }),
                                            }
                                        }
                                            onChange={e => { setCompanyScript({ value: e.value, label: e.label }); setBrandError('') }} />
                                    </div>
                                    <input placeholder='Новый скрипт' value={newScript} onChange={e => setNewScript(e.target.value)} />
                                    <button onClick={AddScript}>Добавить</button>
                                </div>
                                <div className={styles.scriptBlocks}>
                                    {
                                        scripts?.map(el => <div className={styles.Script} key={el}>
                                            {el}
                                            <div className={styles.icons}>
                                                <ClearRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => handleRemoveScript(el)} />
                                            </div>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={styles.middleBlock}>
                            <div className={styles.AddWord}>
                                <h3>Добавить ключевые слова</h3>
                                <div className={styles.wordForm}>
                                    <div className={styles.responsiveSelect}>

                                    <div className={styles.selectWord}>
                                        <Select options={companies} placeholder={'Выберите бренд'} value={companyWord} styles={
                                            {
                                                control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', padding: '0px', margin: '0px' }),
                                                placeholder: (styles) => ({ ...styles, textAlign: 'start', padding: '0px' }),
                                                option: (styles) => ({ ...styles, textAlign: 'start' }),
                                                singleValue: (styles) => ({ ...styles, textAlign: 'start', padding: '0px' }),
                                                input: (styles) => ({ ...styles, textAlign: 'start', padding: '0px', margin: '0px' }),
                                            }
                                        }
                                            onChange={e => { setCompanyWord({ value: e.value, label: e.label }); setBrandError('') }} />
                                    </div>
                                    <textarea placeholder='Ключевые слова' value={words} onChange={e=>setWords(e.target.value)}/>
                                    </div>
                                    <div className={styles.buttons}>
                                        <button className={styles.buttonPositive} onClick={AddPositiveWords}>Добавить <SentimentVerySatisfiedRoundedIcon/></button>
                                        <button className={styles.buttonNegative} onClick={AddNegativeWords}>Добавить <SentimentDissatisfiedRoundedIcon/></button>
                                    </div>
                                </div>
                                <div className={styles.WordBLock}>
                                    <div className={styles.WordsPositive}>
                                        <h4>Положительные:</h4>
                                        <div className={styles.Words}>
                                            {wordsListPositive?.map(el=> <div key={el.word} className={styles.word}>
                                                <span>{el.word}</span>
                                                <ClearRoundedIcon sx={{cursor:'pointer'}}  onClick={()=>handleRemoveWordListPositive(el)}/>
                                            </div>)}
                                        </div>
                                    </div>
                                    <div className={styles.WordsNegative}>
                                        <h4>Отрицательные:</h4>
                                        <div className={styles.Words}>
                                            {wordsListNegative?.map(el=> <div key={el.word} className={styles.word}>
                                                <span>{el.word}</span>
                                                <ClearRoundedIcon sx={{cursor:'pointer'}}  onClick={()=>handleRemoveWordListNegative(el)}/>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.usersTable}>

                            <MaterialReactTable table={table}  />
                            </div>
                        </div>
                        <div className={styles.bottomBlock}>
                            <div className={styles.companiesPresentation}>
                                <h3>Представленные компании</h3>
                                <Select options={companies} placeholder={'Выберите бренд'} value={companyPres} styles={
                                    {
                                        control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', padding: '0px', margin: '0px' }),
                                        placeholder: (styles) => ({ ...styles, textAlign: 'start', padding: '0px' }),
                                        option: (styles) => ({ ...styles, textAlign: 'start' }),
                                        singleValue: (styles) => ({ ...styles, textAlign: 'start', padding: '0px' }),
                                        input: (styles) => ({ ...styles, textAlign: 'start', padding: '0px', margin: '0px' }),
                                    }
                                }
                                    onChange={e => { setCompanyPres({ value: e.value, label: e.label }); setBrandError('') }} />
                                    
                            <div className={styles.companies}>
                                {
                                    Object.entries(companiesPres).length === 0
                                    ?<div className={styles.emptySet}><SentimentNeutralRoundedIcon sx={{width:'70px',height:'70px',color:'#2469E9'}}/><span>Нет названных компаний</span></div>
                                    :Object.entries(companiesPres).map(([key, value]) => (
                                        <div key={key} className={styles.companyPres}>
                                            <div className={styles.companyIcon}>
                                                <span className={styles.icon}>
                                                    <ApartmentRoundedIcon/>
                                                </span>
                                                {key == 'UNKNOWN'?'Не названа':key}:
                                            </div>
                                            <span style={{fontWeight:700}}>{value}</span>
                                            
                                        </div>
                                    ))
                                }
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <h3 style={{textAlign:'center',margin:'0px'}}>Изменить пользователя <br/>{user.username}</h3>
                        <input placeholder='Логин' value={userEditLogin? userEditLogin: null} onChange={e=> setUserEditLogin(e.target.value)} style={{width:'calc(100% - 10px)' , paddingLeft:'10px',fontFamily:'"Geologica", sans-serif',fontSize:'16px',height:'40px',borderRadius:'6px',border:'2px solid grey'}}/>
                        <input placeholder='Новый пароль' value={newPassword} onChange={(e=> setNewPassword(''))} style={{width:'calc(100% - 10px)' , paddingLeft:'10px',fontFamily:'"Geologica", sans-serif',fontSize:'16px',height:'40px',borderRadius:'6px',border:'2px solid grey'}}/>
                        {
                            user?.companies?.length <=1?
                            <div  style={{width:'100%'}}>
                                <Select options={editTeams} value={editTeam} placeholder={'Выберите команду'} styles={
                                            {
                                                control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', marginRight: '2px',height:'35px' }),
                                                placeholder: (styles) => ({ ...styles, textAlign: 'start' }),
                                                option: (styles) => ({ ...styles, textAlign: 'start' }),
                                                singleValue: (styles) => ({ ...styles, textAlign: 'start' }),
                                                input: (styles) => ({ ...styles, textAlign: 'start', padding: '0px', margin: '0px',height:'32px' }),
                                            }
                                        }
                                    onChange={e => { setEditTeam({ value: e.value, label: e.label }) }} />
    
                            </div>:''
                        }                     
                        <div  style={{width:'100%'}}>
                            <Select options={roles} value={editRole} placeholder={'Выберите роль'} styles={
                                        {
                                            control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', marginRight: '2px',height:'35px' }),
                                            placeholder: (styles) => ({ ...styles, textAlign: 'start' }),
                                            option: (styles) => ({ ...styles, textAlign: 'start' }),
                                            singleValue: (styles) => ({ ...styles, textAlign: 'start' }),
                                            input: (styles) => ({ ...styles, textAlign: 'start', padding: '0px', margin: '0px',height:'32px' }),
                                        }
                                    }
                                onChange={e => { setEditRole({ value: e.value, label: e.label }) }} />
                        </div>
                        <button onClick={EditUser} style={{display:'flex' , alignItems:'center',justifyContent:'center',border:'none',backgroundColor:'#FFF1D6',color:'#B76E00',fontFamily:'"Geologica", sans-serif',height:'40px',width:'140px',borderRadius:'8px',gap:'5px',padding:'4px 8px',fontWeight:'500',cursor:'pointer',fontSize:'16px'}}>Изменить <EditRoundedIcon/></button>
                    </Box>
                </Modal>
            </div>
        )
}
