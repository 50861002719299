import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Home from './pages/Landing/Home/Home';
import Login from './pages/Landing/Login/Login';
import Dashboard from './pages/Crm/Dashboard/Dashboard';
import Agents from './pages/Agents/Agents';
import Calls from './pages/Calls/Calls';
import Scripts from './pages/Scripts/Scripts';
import { useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import ProtectedRoute from './components/ProtectedRoute'; // Импортируйте ваш новый компонент
import SoloCall from './pages/SoloCall/SoloCall';
import Agent from './pages/Agent/Agent';
import Admin from './pages/Admin/Admin';
import Billing from './pages/Billing/Billing';
import i18n from './i18n/config';
import CallsByTag from './pages/CallsByTag/CallsByTag';
function App() {
  const nav = useNavigate();

  useEffect(() => { 
    const verifyToken = async () => {
      const token = secureLocalStorage.getItem('token');
      const isAuth = secureLocalStorage.getItem('isAuth') === true;

      if (token && isAuth) {
        try {
          const result = await axios.post('https://base.calltracker.ai/api/token/verify/', { token });
          if (result.data.message === 'invalid token') {
            secureLocalStorage.removeItem('token');
            nav('/login');
          }
        } catch (err) {
          nav('/login');
        }
      }
    };

    verifyToken();

    const interval = setInterval(verifyToken, 30000);
    return () => clearInterval(interval);
  }, [nav]);
  useEffect(()=>{
    if(secureLocalStorage.getItem('lang')){
      i18n.changeLanguage(secureLocalStorage.getItem('lang'));
    }
    else{
      i18n.changeLanguage("ru");
    secureLocalStorage.setItem("lang","ru");
    }
  },[])
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/dashboard' element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } />
        <Route path='/agents' element={
          <ProtectedRoute>
            <Agents />
          </ProtectedRoute>
        } />
        <Route path='/calls' element={
          <ProtectedRoute>
            <Calls />
          </ProtectedRoute>
        } />
        <Route path='/calls/tag/:tag/:company' element={
          <ProtectedRoute>
            <CallsByTag />
          </ProtectedRoute>
        } />
        <Route path='/scripts' element={
          <ProtectedRoute>
            <Scripts />
          </ProtectedRoute>
        } />
        <Route path='/call/:id' element={
          <ProtectedRoute>
            <SoloCall />
          </ProtectedRoute>
        } />
        <Route path='/agent/:id' element={
          <ProtectedRoute>
            <Agent />
          </ProtectedRoute>
        } />
        <Route path='/admin' element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        } />
        <Route path='/billing' element={
          <ProtectedRoute>
            <Billing />
          </ProtectedRoute>
        } />
      </Routes>
    </div>
  );
}

export default App;
