import React, { useEffect, useState } from 'react';
import styles from './SoloCall.module.scss';
import CrmNavBar from '../../components/CrmNavBar';
import TopBar from '../../components/TopBar';
import { useParams } from 'react-router-dom';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import SentimentSatisfiedAltRoundedIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import SentimentVerySatisfiedRoundedIcon from '@mui/icons-material/SentimentVerySatisfiedRounded';
import axios from 'axios';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import secureLocalStorage from 'react-secure-storage';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import moment from 'moment';
import { CircularProgress, Modal, Tooltip, circularProgressClasses, styled, tooltipClasses } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Loading from '../../components/Loading';
import { WaveSurferPlayer } from '../../components/AudioWaveForm';
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js'
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded';
import CallEndRoundedIcon from '@mui/icons-material/CallEndRounded';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useTranslation } from 'react-i18next';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Select from 'react-select';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 2,
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexDirection: 'column',
    gap: '20px',
    borderRadius: '12px'
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ padding: 0 }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SoloCall() {
    const sentiments = [{value:1 , label:'POSITIVE'},{value:2 , label:'NEUTRAL'},{value:3 , label:'NEGATIVE'}]
    const callEnds = [
        {value:1,label:'Приветствие'},
        {value:2,label:'Сбор информации'},
        {value:3,label:'Презентация'},
        {value:4,label:'Пример'},
        {value:5,label:'Презентация личного эксперта'},
        {value:6,label:'Переход в messenger'},
    ]
    const { id } = useParams();
    const [call, setCall] = useState({});
    const [value, setValue] = useState(0); // Обновлено значение по умолчанию
    const [messages, setMessages] = useState([]);
    const [audioUrl, setAudioUrl] = useState('');
    const [scripts, setScripts] = useState([]);
    const [lang, setLang] = useState('Or');
    const [open, setOpen] = React.useState(false);
    const [companyPresentation ,setCompanyPresentation] = useState('')
    const [isToxic ,setIsToxic] = useState(false)
    const [isFamiliar ,setIsFamiliar] = useState(false)
    const [callEnd ,setCallEnd] = useState({})
    const [sentiment ,setSentiment] = useState({})

    const handleOpen = () => {
        setOpen(true)
        setIsToxic(call?.toxic)
        setIsFamiliar(call?.familiar)
        setSentiment(sentiments.find(el=> el.label === call?.sentiment))
        setCallEnd(callEnds.find(el=> el.label.toLowerCase() === call?.stage.toLowerCase()))
        setCompanyPresentation(call?.presentation_company)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setIsToxic(false)
        setIsFamiliar(false)
        setSentiment({})
        setCallEnd({})
        setCompanyPresentation('')
    };

    const handleClose = () => { setOpen(false) };
    useEffect(() => {
        const fetchCall = async () => {
            try {
                const { data } = await axios.get(`https://base.calltracker.ai/api/calls/${id}/`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                });
                if (data) {
                    console.log(data);
                    setCall(data);
                    setScripts(data.qa_json)
                    parseMessages(data.transcription_with_diar);
                    fetchAudio(id)
                }
            } catch (e) {
                console.log(e);
            }
        }
        fetchCall();
    }, [id]);
    const fetchAudio = async (uuid) => {
        try {
            const response = await axios.post(
                'https://base.calltracker.ai/api/calls/getrecord/',
                { uuid },
                {
                    responseType: 'blob',
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                }
            );
            const url = URL.createObjectURL(new Blob([response.data], { type: 'audio/wav' }));
            setAudioUrl(url);
        } catch (e) {
            console.log(e);
        }
    };
    function formatDuration(seconds) {
        const totalSeconds = Math.floor(seconds);
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    function parseMessages(input) {
        const regex = /\((\d+\.\d+), (\d+\.\d+)\)\[(.*?)\]:(.+?)(?=\(\d+\.\d+, \d+\.\d+\)|$)/gs;
        const parsedMessages = [];
        let match;
        while ((match = regex.exec(input)) !== null) {
            const startTime = match[1];
            const endTime = match[2];
            const speakers = match[3].replace(/'/g, '').split(', ').map(s => s.replace('SPEAKER_', 'Speaker '));
            const message = match[4].trim();
            parsedMessages.push({ startTime, endTime, speakers, message });
        }
        setMessages(parsedMessages);
    }
    useEffect(() => {
        if (lang === 'Or') {
            const fetchTranslation = async () => {
                try {

                    const { data } = await axios.get(`https://base.calltracker.ai/api/calls/${id}/`, {
                        headers: {
                            "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                        }
                    });
                    if (data) {
                        setScripts(data.qa_json)
                        parseMessages(data.transcription_with_diar);
                    }
                } catch (e) {

                }
            }
            fetchTranslation()
        }
        if (lang === 'Ru') {
            const fetchTranslation = async () => {
                try {
                    const { data } = await axios.post('https://base.calltracker.ai/api/get-translated-text/', {
                        call_id: id,
                        target_language: "RU"
                    }, {

                        headers: {
                            "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                        }
                    })
                    if (data) {
                        parseMessages(data.translated_text)
                        setScripts(data.translated_script)
                    }
                } catch (e) {

                }
            }
            fetchTranslation()

        }
    }, [lang])
    const EditCall = async () => {
        try{
            const {data} = await axios.post('https://base.calltracker.ai/api/fine-tune/', {
                call_id: id,
                familiar: isFamiliar,
                toxic: isToxic,
                sentiment: sentiment.label,
                call_stage: callEnd.label,
                presentation_company: companyPresentation
            }, {

                headers: {
                    "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                }
            })
            if(data){
                handleClose()
                console.log('success')
                
                const call = await axios.get(`https://base.calltracker.ai/api/calls/${id}/`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                });
                if(call){
                    setCall(call.data)
                }
            }
        }catch(e){
            console.log(e)
        }
    }
    const { t } = useTranslation();
    console.log(call)
    return (
        <div className={styles.page}>
            <CrmNavBar />
            <div className={styles.body}>
                <TopBar />
                <div className={styles.content}>
                    <div className={styles.CallTop}>
                        <div className={styles.CallId}>
                            <span className={styles.Id}><CallRoundedIcon />{id} <PhoneIphoneRoundedIcon/>{call.dst}</span>
                            {
                                !call.processed ? "" :
                                    <span className={styles.sentiment} style={call.sentiment === 'POSITIVE' ? { backgroundColor: 'rgba(34, 197, 94, 0.16)', color: 'rgb(17, 141, 87)' } : call.sentiment === 'NEGATIVE' ? { backgroundColor: '#FFE4DE', color: 'rgb(183, 29, 24)' } : { backgroundColor: '#EEF4FE', color: '#266EF4' }}>
                                        {call.sentiment === 'NEUTRAL' ? <SentimentSatisfiedAltRoundedIcon />
                                            : call.sentiment === 'POSITIVE' ?
                                                <SentimentVerySatisfiedRoundedIcon />
                                                : <SentimentDissatisfiedRoundedIcon />}
                                        {call.sentiment === 'NEUTRAL' ? t('CallsTableSentimentNeutral') : call.sentiment === 'POSITIVE' ? t('CallsTableSentimentPositive') : t('CallsTableSentimentNegative')}</span>
                            }
                            <span className={styles.agent}><SupportAgentRoundedIcon />{call.agent_name}</span>
                            <span className={styles.length} ><AccessTimeRoundedIcon />{formatDuration(call.length)} {t('MIN')}</span>
                            {
                                call.processed ?
                                    <Tooltip className={styles.updated} arrow placement='bottom' title={<span style={{ fontFamily: '"Geologica", sans-serif' }}>{t('SoloCallDate')}</span>}>
                                        <UpdateRoundedIcon />{moment(call.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                                    </Tooltip>
                                    :
                                    <Tooltip className={styles.updated} arrow placement='bottom' title={<span style={{ fontFamily: '"Geologica", sans-serif' }}>{t('CallsTableProcessing')}</span>}>
                                        <UpdateRoundedIcon />{t('CallsTableProcessing')}
                                    </Tooltip>
                            }
                            {
                                !call.processed ? "" : <Tooltip
                                    className={call.familiar ? styles.familiar : styles.nofamiliar}
                                    arrow
                                    title={call.familiar ?
                                        <span style={{ fontFamily: '"Geologica", sans-serif' }}>{t('SoloCallAcquaintanceYesText')}</span>
                                        : <span style={{ fontFamily: '"Geologica", sans-serif' }}>{t('SoloCallAcquaintanceText')}</span>}
                                    placement='bottom'>
                                    {call.familiar ? <GroupAddRoundedIcon /> : <GroupRemoveRoundedIcon />} {t('SoloCallAcquaintance')}
                                </Tooltip>}
                            {
                                !call.processed ? "" : <Tooltip
                                    className={styles.callEnd}
                                    arrow
                                    title={<span style={{ fontFamily: '"Geologica", sans-serif' }}>{t('SoloCallStageText')} {call.stage}</span>}
                                    placement='bottom'><CallEndRoundedIcon /> {call.stage ? call?.stage[0].toUpperCase() + call.stage.slice(1).toLowerCase() : ''}
                                </Tooltip>}
                            {
                                !call.processed ? "" : <Tooltip
                                    className={call.toxic ? styles.toxic : styles.notoxic}
                                    arrow
                                    title={<span style={{ fontFamily: '"Geologica", sans-serif' }}>{call.toxic ? t('SoloCallToxicYesText') : t('SoloCallToxicText')}</span>}
                                    placement='bottom'>{call.toxic ? <ThumbDownAltRoundedIcon /> : <ThumbUpRoundedIcon />} {call.toxic ? t('CallsTableToxicty') : t('CallsTableNoToxicty')}
                                </Tooltip>}
                            {
                                !call.processed ? "" : <Tooltip
                                    className={styles.callEnd}
                                    arrow
                                    title={!call.presentation_company || call.presentation_company === 'UNKNOWN' ? <span style={{ fontFamily: '"Geologica", sans-serif' }}>{t('SoloCallPresentationCompanyNo')}</span> : <span style={{ fontFamily: '"Geologica", sans-serif' }}>{t('SoloCallPresentationCompany')}</span>}
                                    placement='bottom'><ApartmentRoundedIcon /> {!call.presentation_company || call.presentation_company === 'UNKNOWN' ? t('SoloCallUnknown') : call.presentation_company}
                                </Tooltip>}
                        </div>
                        <div className={styles.CallContent}>
                            {call.processed ?
                                <>
                                    <div className={styles.ScriptPercentage}>
                                        <h3>{t('AgentsTableScript')}</h3>
                                        <CircularProgress size='8vw' variant="determinate" thickness={2.6} value={call.qa_percentage} sx={{
                                            marginTop: '10px',
                                            borderRadius: '50%',
                                            boxShadow: `inset 0 0 0 ${2.6 / 44 * 8}vw ${'rgba(145, 158, 171, 0.16)'}`,
                                            [`& .${circularProgressClasses.circle}`]: {
                                                stroke: '#00A76F',
                                                strokeLinecap: 'round',
                                            },
                                        }} />
                                        <p className={styles.count}>{call.qa_percentage}%</p>
                                    </div>
                                    <div className={styles.ScriptPercentage}>
                                        <h3>{t('AgentsTableScores')}</h3>
                                        <CircularProgress size='8vw' variant="determinate" thickness={2.6} value={call.score} sx={{
                                            marginTop: '10px',
                                            borderRadius: '50%',
                                            boxShadow: `inset 0 0 0 ${2.6 / 44 * 8}vw ${'rgba(145, 158, 171, 0.16)'}`,
                                            [`& .${circularProgressClasses.circle}`]: {
                                                stroke: '#266EF4',
                                                strokeLinecap: 'round',
                                            },
                                        }} />
                                        <p className={styles.count}>{call.score}</p>
                                    </div>

                                </> : <div style={{ width: '27rem', height: '10rem', alignItems: 'start', justifyContent: 'start', display: 'flex' }}>
                                    <span style={{ width: '100%', display: 'flex', gap: '10px', alignItems: 'start', justifyContent: 'start', padding: '20px', backgroundColor: '#FFF1D6', color: "#B76E00", border: '1px solid #B76E00', borderRadius: '12px' }}>
                                        <InfoRoundedIcon />{t('CallsTableProcessing')}
                                    </span>
                                </div>}
                            <div className={styles.record}>
                                {
                                    !audioUrl ? <Loading /> :
                                        <WaveSurferPlayer
                                            height={110}
                                            waveColor="#D1D6DA"
                                            progressColor="#266EF4"
                                            url={audioUrl}
                                            plugins={[Timeline.create(), Hover.create({ labelColor: 'white', labelTop: '30px', position: 'absolute', top: '50px', labelBackground: 'black', labelSize: '11px', lineColor: 'black' })]}
                                        />
                                }
                            </div>
                        </div>
                        {secureLocalStorage.getItem('roleName') === 'Admin' && call.processed ?
                            <button className={styles.update} onClick={() => handleOpen()}><UpdateRoundedIcon />{t('Update')}</button>
                            : ''}
                    </div>
                    <div className={styles.CallBottom}>
                        <div className={styles.CallPanel} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={t("SoloCallTranscription")} {...a11yProps(0)} sx={{ fontFamily: '"Geologica", sans-serif' }} />
                                <Tab label={t("SoloCallAnalyze")} {...a11yProps(1)} sx={{ fontFamily: '"Geologica", sans-serif' }} />
                            </Tabs>
                            {
                                call?.agent_name?.includes('alpha') && lang === 'Or' ?
                                    <p onClick={() => setLang('Ru')} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', backgroundColor: '#EEF4FE', color: '#266EF4', padding: '6px 12px', fontWeight: 'bold', borderRadius: '6px', gap: '6px' }}><LanguageRoundedIcon />Russia</p> : ''
                            }
                            {
                                call?.agent_name?.includes('alpha') && lang === 'Ru' ?
                                    <p onClick={() => setLang('Or')} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', backgroundColor: '#EEF4FE', color: '#266EF4', padding: '6px 12px', fontWeight: 'bold', borderRadius: '6px', gap: '6px' }}><LanguageRoundedIcon />Original</p> : ''
                            }
                        </div>
                        <div className={styles.CallTabContent}>
                            <CustomTabPanel value={value} index={0} sx={{ padding: '0px' }}>
                                <div className={styles.messageList}>
                                    {messages.map((msg, index) => (
                                        <li className={styles.message} key={index}>
                                            <strong style={{ width: '130px' }}>{formatDuration(msg.startTime)} - {formatDuration(msg.endTime)}</strong>
                                            <strong>{msg.speakers?.join(', ')}:</strong>
                                            <div style={{ width: '70%' }}>{msg.message}</div>
                                        </li>
                                    ))}
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <div className={styles.Analyze}>
                                    <div className={styles.Words}>
                                        <h3>{t('SoloCallWords')}</h3>
                                        <h4>{t('SoloCallWordsPositive')}:</h4>
                                        <div className={styles.Positive}>
                                            {call.positive_word_count && Object.entries(call.positive_word_count).map(([key, value], index) => (
                                                <span className={styles.word} key={index}>
                                                    <span>{key}:</span> <strong>{value}</strong>
                                                </span>
                                            ))}
                                        </div>
                                        <h4>{t('SoloCallWordsNegative')}:</h4>
                                        <div className={styles.Negative}>
                                            {call.negative_word_count && Object.entries(call.negative_word_count).map(([key, value], index) => (
                                                <span className={styles.word} key={index}>
                                                    <span>{key}:</span> <strong>{value}</strong>
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={styles.Questions}>
                                        <h3>{t('SoloCallScript')}</h3>
                                        <div className={styles.QuestionsList}>
                                            {scripts && Object.entries(scripts).map(([key, value], index) => (
                                                <div className={styles.question} key={index}>
                                                    <span>{key}:</span> <strong>{value}</strong>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </CustomTabPanel>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center', margin: '0px' }}>Изменить звонок</h3>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <label className="lns-checkbox">
                            <input type="checkbox" name='toxicity' checked={isToxic} onChange={(e) => setIsToxic(e.target.checked)} />
                            <span>Токсичность</span>
                        </label>
                        <label className="lns-checkbox">
                            <input type="checkbox" name='toxicity' checked={isFamiliar} onChange={(e) => setIsFamiliar(e.target.checked)} />
                            <span>Знакомы</span>
                        </label>
                    </div>
                    <div className={styles.select} style={{ width: '100%' }}>
                        <Select options={sentiments} value={sentiment} onChange={e => setSentiment({ value: e.value, label: e.label })} />
                    </div>
                    <div className={styles.select} style={{ width: '100%' }}>
                        <Select options={callEnds} value={callEnd} onChange={e => setCallEnd({ value: e.value, label: e.label })} />
                    </div>
                    <input value={companyPresentation} onChange={e=> setCompanyPresentation(e.target.value)} placeholder='Представленная компания' style={{ width: 'calc(100% - 25px)', padding: '10px 10px', margin: '0', fontFamily: '"Geologica", sans-serif', borderRadius: '4px', border: '1px solid #979797' }} />
                    <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'start',fontSize:'14px',gap:'10px',color:"#b76e00"}}>
                        <WarningRoundedIcon/>
                        <p>Если агент не представил компанию вставить в данное поле <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={()=> setCompanyPresentation('UNKNOWN')}><b>UNKNOWN</b></span></p>
                    </div>
                    <button onClick={EditCall} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', backgroundColor: '#FFF1D6', color: '#B76E00', fontFamily: '"Geologica", sans-serif', height: '40px', width: '140px', borderRadius: '8px', gap: '5px', padding: '4px 8px', fontWeight: '500', cursor: 'pointer', fontSize: '16px' }}>Изменить <EditRoundedIcon /></button>
                </Box>
            </Modal>
        </div>
    );
}
