import React from 'react'
import styles from '../styles/TopBar.module.scss'
import secureLocalStorage from 'react-secure-storage';
import { useTranslation } from 'react-i18next';
export default function TopBar() {
  const {t,i18n} = useTranslation()
  function changeLanguage(e) {
    i18n.changeLanguage(e);
  }
  return (
    <div className={styles.topbar}>
        <div className={styles.buttons}>
        {
                    i18n.language == "ru" &&(
                        <span className={styles.lang}
                            onClick={() => { changeLanguage("en"); secureLocalStorage.setItem("lang", "en") }}>Ру</span>
                    )
        }
        {
          
          i18n.language == "en" &&(
          <span className={styles.lang}
          onClick={() => { changeLanguage("es"); secureLocalStorage.setItem("lang", "es") }}>En</span>)
        }
        {
          
          i18n.language == "es" &&(
          <span className={styles.lang}
          onClick={() => { changeLanguage("ru"); secureLocalStorage.setItem("lang", "ru") }}>Es</span>)
        }
        </div>
    </div>
  )
}
