import React from 'react'
import { PieChart} from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';


export default function Pie({value,title ,mood}) {
    const {t} = useTranslation()
    console.log(mood)
    const data = [
        { id: 0, value: mood?.neutral, label: t('DashboardSentimentsNeutral'), color:"#FFAB00" },
        { id: 1, value: mood?.negative, label: t('DashboardSentimentsNegative'), color:"#FF5630" },
        { id: 2, value: mood?.positive, label: t('DashboardSentimentsPositive'), color:"#266EF4" },
    ];
    
    const sizing = {
        margin: { right: 5 },
        legend: { hidden: true },
      };
    
    const StyledText = styled('text')(({ theme }) => ({
      fill:'#000',
      textAnchor: 'middle',
      dominantBaseline: 'central',
      fontSize: 24,
    }));
    function PieCenterLabel({ children }) {
      const { width, height, left, top } = useDrawingArea();
      const [total, label] = children.split(' ');

      return (
          <StyledText x={left + width / 2} y={top + height / 2}>
              <tspan x={left + width / 2} dy="-0.5em">{total}</tspan>
              <tspan x={left + width / 2} dy="1.2em">{label}</tspan>
          </StyledText>
      );
  }
    return (
        <PieChart
            series={[
                {
                    data,
                    innerRadius: 95,
                    paddingAngle: 3,
                    cornerRadius: 4,
                    arcLabel: (params) => { return '' },
                },
            ]}
            slotProps={{
                legend: {
                    hidden: true,
                    position: { vertical: 'top', horizontal: 'middle' },
                    padding: 0,
                },
            }}
            height={180}
            skipAnimation={true}
            {... sizing}
        >
        <PieCenterLabel>{mood?.neutral + mood?.negative + mood?.positive + t('DashboardSentimentCalls')}</PieCenterLabel>
      </PieChart>
    );
}
