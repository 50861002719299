import React, { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { useTranslation } from 'react-i18next';

export default function BillingChart({ billingWeek }) {
    const [chartHeight, setChartHeight] = useState(350);
    const {t} = useTranslation()
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1300) {
                setChartHeight(200);
            } else {
                setChartHeight(350);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set the initial height

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const sizing = {
        legend: { hidden: true },
    };

    const getLastSevenDays = () => {
        const days = [];
        for (let i = 6; i >= 0; i--) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            const formattedDate = date.toISOString().split('T')[0];
            days.push(formattedDate);
        }
        return days;
    };

    const xAxisData = getLastSevenDays();

    return (
        <LineChart
            sx={{
                fontFamily: '"Geologica",sans-serif',
                "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                    strokeWidth: 0,
                    fill: '#000',
                    fontFamily: '"Geologica",sans-serif',
                },
                "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                    fontFamily: "'Geologica',sans-serif",
                },
                "& .MuiChartsAxis-tick ": {
                    stroke: '#000',
                    strokeWidth: 0
                },
                '& .MuiLineElement-root': {
                    strokeWidth: 4
                },
                "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                    strokeWidth: "1",
                    fill: '#000',
                    fontFamily: "'Geologica',sans-serif",
                    transform: 'scale(85%)',
                },
                '& .MuiAreaElement-series-green': {
                    fill: "url(#SvgjsLinearGradient5114)",
                },
                '& .MuiAreaElement-series-red': {
                    fill: "url('#SvgjsLinearGradient4848')",
                },
                "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                    stroke: '#000',
                    strokeWidth: 0
                },
                "& .MuiLineHighlightElement-root": {
                    stroke: '#000',
                    strokeWidth: 1
                },
                "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                    stroke: '#000',
                    strokeWidth: 0
                },
                "& .MuiChartsAxis-left": {
                    stroke: '#000',
                    display: 'block'
                }
            }}
            xAxis={[{
                scaleType: 'point', data: xAxisData
            }]}
            series={[
                {
                    id: 'green',
                    data: billingWeek,
                    area: true,
                    showMark: true,
                    label: t('BalanceSpending'),
                    color: '#00A76F',
                    legend: { hidden: true }
                },
            ]}
            height={chartHeight}
            {...sizing}
        />
    );
}
