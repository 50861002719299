import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.scss'
import CrmNavBar from '../../../components/CrmNavBar'
import TopBar from '../../../components/TopBar'
import Pie from '../../../components/PieChart';
import GraphChart from '../../../components/GraphChart';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import ScoreChart from '../../../components/ScoreChart';
import SimilarityChart from '../../../components/SimilarityChart';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import SentimentNeutralRoundedIcon from '@mui/icons-material/SentimentNeutralRounded';
import { Link } from 'react-router-dom';
export default function Dashboard() {
    const {t} = useTranslation()
    const [mood, setMood] = useState({})
    const [similarity, setSimilarity] = useState({})
    const [agentScore, setAgentScore] = useState(0)
    const [positiveCalls, setPositiveCalls] = useState([])
    const [negativeCalls, setNegativeCalls] = useState([])
    const [scoresWeek, setScoresWeek] = useState([])
    const [company, setCompany] = useState({})
    const [type, setType] = useState('all');
    const [companies , setCompanies] = useState([])
    const [companyPresentaion , setCompanyPresentation] = useState({})
    const [timeFrame , setTimeFrame] = useState('all')
    useEffect(()=>{
        
        const fetchData = async () => {
            try {
                if (secureLocalStorage.getItem('role') || companies.length > 1) {
                    const { data } = await axios.post(`https://base.calltracker.ai/api/dashboard`, {
                        company_name: company.label,
                        team_type: type
                    }, {
                        headers: {
                            "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                        }
                    })
                    if (data) {
                        setCompanyPresentation(data.company_presentation)
                        setMood(data.mood)
                        setSimilarity(data.similarity)
                        setAgentScore(data.average_agent_score)
                        setPositiveCalls(data.toxic_calls.positive)
                        setNegativeCalls(data.toxic_calls.negative)
                        setScoresWeek(data.daily_average_scores)
                        let temp = []
                        data.companies.forEach(el=>{
                            temp.push({value:el.id,label: el.name})
                        })
                        setCompanies(temp)
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
        fetchData()
    },[company])
    
    useEffect(()=>{
        const fetchData = async () => {
            try {
                if (secureLocalStorage.getItem('role') || companies.length > 1) {
                    const { data } = await axios.post(`https://base.calltracker.ai/api/dashboard`, {
                        company_name: company.label,
                        team_type: type,
                        timeframe: timeFrame
                    }, {
                        headers: {
                            "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                        }
                    })
                    if (data) {
                        setCompanyPresentation(data.company_presentation)
                        setMood(data.mood)
                        setSimilarity(data.similarity)
                        setAgentScore(data.average_agent_score)
                        setPositiveCalls(data.toxic_calls.positive)
                        setNegativeCalls(data.toxic_calls.negative)
                        setScoresWeek(data.daily_average_scores)
                        let temp = []
                        data.companies.forEach(el=>{
                            temp.push({value:el.id,label: el.name})
                        })
                        setCompanies(temp)
                    }
                }else{
                    const { data } = await axios.post(`https://base.calltracker.ai/api/dashboard`, {
                        team_type: type
                    }, {
                        headers: {
                            "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                        }
                    })
                    if (data) {
                        setCompanyPresentation(data.company_presentation)
                        setMood(data.mood)
                        setSimilarity(data.similarity)
                        setAgentScore(data.average_agent_score)
                        setPositiveCalls(data.toxic_calls.positive)
                        setNegativeCalls(data.toxic_calls.negative)
                        setScoresWeek(data.daily_average_scores)
                        let temp = []
                        data.companies.forEach(el=>{
                            temp.push({value:el.id,label: el.name})
                        })
                        setCompanies(temp)
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
        fetchData()
    },[type , timeFrame])
    const timeFrames = [
        {value:'all',label:'all'},
        {value:'day',label:'day'},
        {value:'week',label:'week'},
        {value:'month',label:'month'},
    ]
    console.log(timeFrame)
    return (
        <div className={styles.page}>
            <CrmNavBar />
            <div className={styles.body}>
                <TopBar />
                <div className={styles.content}>
                    {
                        secureLocalStorage.getItem('role') ?
                            <div className={styles.companyChoose} style={{justifyContent:'space-between'}}>
                                <div style={{display:'flex' , alignItems:'center',justifyContent:'start',gap:'10px'}}>
                                {
                                    companies.length > 1?
                                    <div className={styles.select}>
                                        <Select options={companies} onChange={e=> setCompany({value:e.value , label:e.label})}/>
                                    </div>:''
                                }
                                <button style={type === 'all' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('all')}>{t('DashboardFilterAll')}</button>
                                <button style={type === 'ret' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('ret')}>RET</button>
                                <button style={type === 'sale' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('sale')}>SALE</button>
                                </div>
                                    <Select options={timeFrames} value={{value:timeFrame, label:timeFrame}} onChange={e=> setTimeFrame(e.value)}/>
                            </div> : 
                            secureLocalStorage.getItem('roleName') === 'Brand Manager' 
                                                || secureLocalStorage.getItem('roleName') === 'Couch' 
                                                || secureLocalStorage.getItem('roleName') === 'Admin' ?
                                                <div className={styles.companyChoose} style={{justifyContent:'space-between'}}>
                                                    <div style={{display:'flex' , alignItems:'center',justifyContent:'start',gap:'10px'}}>
                                                    {
                                                        companies.length > 1?
                                                        <div className={styles.select}>
                                                            <Select options={companies} onChange={e=> setCompany({value:e.value , label:e.label})}/>
                                                        </div>:''
                                                    }
                                                    <button style={type === 'all' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('all')}>{t('DashboardFilterAll')}</button>
                                                    <button style={type === 'ret' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('ret')}>RET</button>
                                                    <button style={type === 'sale' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('sale')}>SALE</button>
                                                    </div>
                                                    <Select options={timeFrames} value={{value:timeFrame, label:timeFrame}} onChange={e=> setTimeFrame(e.value)}/>
                                                </div>
                            : ''
                    }
                    <div className={styles.chart}>
                        <div className={styles.pieChart}>
                            <h2>{t('DashboardSentimentChart')}</h2>
                            <Pie mood={mood} />
                            <div className={styles.colors}>
                                <span className={styles.color}>
                                    <div style={{ backgroundColor: "#266EF4", height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                    <span>{t('DashboardSentimentsPositive')}</span>
                                </span>
                                <span className={styles.color}>
                                    <div style={{ backgroundColor: "#FFAB00", height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                    <span>{t('DashboardSentimentsNeutral')}</span>
                                </span>
                                <span className={styles.color}>
                                    <div style={{ backgroundColor: "#FF5630", height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                    <span>{t('DashboardSentimentsNegative')}</span>
                                </span>
                            </div>
                        </div>
                        <div className={styles.pieChart}>
                            <h2>{t('DashboardScriptChart')}</h2>
                            <SimilarityChart similarity={similarity} />
                            <div className={styles.colors}>
                                <span className={styles.color}>
                                    <div style={{ backgroundColor: "#FF5630", height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                    <span>{t('DashboardScriptsBad')}</span>
                                </span>
                                <span className={styles.color}>
                                    <div style={{ backgroundColor: "#FFAB00", height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                    <span>{t('DashboardScriptsMiddle')}</span>
                                </span>
                                <span className={styles.color}>
                                    <div style={{ backgroundColor: "#266EF4", height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                    <span>{t('DashboardScriptsGood')}</span>
                                </span>
                                <span className={styles.color}>
                                    <div style={{ backgroundColor: "#00A76F", height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                    <span>{t('DashboardScriptsExcellent')}</span>
                                </span>
                            </div>
                        </div>
                        <div className={styles.scoreChart}>
                            <h2>{t('DashboardAgentScore')}</h2>
                            <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <CircularProgress size='10vw' variant="determinate" thickness={2.6} value={Math.round(agentScore ? agentScore : 0)} sx={{
                                marginTop: '10px',
                                borderRadius: '50%',
                                boxShadow: `inset 0 0 0 ${2.6 / 44 * 10}rem ${'rgba(145, 158, 171, 0.16)'}`,
                                [`& .${circularProgressClasses.circle}`]: {
                                    stroke: '#00A76F',
                                    strokeLinecap: 'round',
                                },

                            }} />

                            </div>
                            <div className={styles.scores}>
                                <h1>{agentScore ? agentScore.toFixed(0) : 0}</h1>
                                <p>{t('DashboardAverageScore')}</p>
                            </div>
                        </div>
                        <div className={styles.companyPresentation}>
                            <h2>{t('CompanyPresentation')}</h2>
                            <div className={styles.companies}>
                                {
                                    Object.entries(companyPresentaion).length === 0
                                    ?<div className={styles.emptySet}><SentimentNeutralRoundedIcon sx={{width:'70px',height:'70px',color:'#2469E9'}}/><span>Нет названных компаний</span></div>
                                    :Object.entries(companyPresentaion).map(([key, value]) => (
                                    <div key={key} className={styles.companyPres}>
                                        <div className={styles.companyIcon}>
                                            <span className={styles.icon}>
                                                <ApartmentRoundedIcon/>
                                            </span>
                                            <Link to={`/calls/tag/${key}/${company.label}`} style={{textDecoration:'none',color:'#000'}}>
                                                {key == 'UNKNOWN'?t('SoloCallUnknown'):key}
                                            </Link>:
                                        </div>
                                        <span style={{fontWeight:700}}>{value}</span>
                                        
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.chart1}>
                        <div className={styles.graphChart}>
                            <div className={styles.legend}>
                                <h2>{t('DashboardScoreChart')}</h2>
                                <div className={styles.colors}>
                                </div>
                            </div>
                            <ScoreChart scoresWeek={scoresWeek} />
                        </div>
                        <div className={styles.graphChart}>
                            <div className={styles.legend}>
                                <h2>{t('DashboardCallsChart')}</h2>
                                <div className={styles.colors}>
                                    <span className={styles.color}>
                                        <div style={{ backgroundColor: "#266EF4", height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                        <span>{t('DashboardCallsChartNoToxic')}</span>
                                    </span>
                                    <span className={styles.color}>
                                        <div style={{ backgroundColor: "#FF5630", height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                        <span>{t('DashboardCallsChartToxic')}</span>
                                    </span>
                                </div>
                            </div>
                            <GraphChart positiveCalls={positiveCalls} negativeCalls={negativeCalls} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
