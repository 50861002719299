import React from 'react'
import styles from '../styles/CrmNavBar.module.scss'
import { Link, useLocation } from 'react-router-dom'
import DonutLargeRoundedIcon from '@mui/icons-material/DonutLargeRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import secureLocalStorage from 'react-secure-storage';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import { useTranslation } from 'react-i18next';
export default function CrmNavBar() {
    const {t} = useTranslation()
    const { pathname } = useLocation()
    const Logout = () =>{
        secureLocalStorage.removeItem('token')
        window.location.href='/login'
    }
    
    return (
        <div className={styles.navbar}>
            <h1>CALLTRACKER</h1>
            <div className={styles.content}>
                <div className={styles.navigation}>
                    <Link to='/dashboard' style={pathname.includes('dashboard') ? { color: 'rgb(38, 110, 244)', backgroundColor: 'rgb(38, 110, 244,0.2)' } : {}}><DonutLargeRoundedIcon />{t('NavBarDashboard')}</Link>
                    <Link to='/agents' style={pathname.includes('agents') ? { color: 'rgb(38, 110, 244)', backgroundColor: 'rgb(38, 110, 244,0.2)' } : {}}><PeopleRoundedIcon />{t('NavBarAgents')}</Link>
                    <Link to={`/calls`} style={pathname.includes('calls') ? { color: 'rgb(38, 110, 244)', backgroundColor: 'rgb(38, 110, 244,0.2)' } : {}}><PhoneInTalkRoundedIcon />{t('NavBarCalls')}</Link>
                    <Link to='/scripts' style={pathname.includes('scripts') ? { color: 'rgb(38, 110, 244)', backgroundColor: 'rgb(38, 110, 244,0.2)' } : {}}><DescriptionRoundedIcon />{t('NavBarScript')}</Link>
                    {secureLocalStorage.getItem('roleName') === 'Admin'?<Link to='/billing' style={pathname.includes('billing') ? { color: 'rgb(38, 110, 244)', backgroundColor: 'rgb(38, 110, 244,0.2)' } : {}}><AccountBalanceWalletRoundedIcon />{t('NavBarBalance')}</Link>:null}
                </div>
                <div className={styles.logout}>
                    {secureLocalStorage.getItem('roleName') === 'Admin'?<Link to='/admin' style={pathname.includes('admin') ? { color: 'rgb(38, 110, 244)', backgroundColor: 'rgb(38, 110, 244,0.2)' } : {}}><ManageAccountsRoundedIcon />{t('NavBarAdmin')}</Link>:null}
                    <button onClick={Logout}><LogoutRoundedIcon />{t('NavBarLogout')}</button>
                </div>
            </div>
        </div>
    )
}
