import React, { useEffect, useMemo, useState } from 'react'
import styles from './Agents.module.scss'
import CrmNavBar from '../../components/CrmNavBar'
import TopBar from '../../components/TopBar'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { Link } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Typography } from '@mui/material';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} color={props.value < 49?'error':'success'}/>
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary" sx={props.value < 49?{fontFamily:'"Geologica", sans-serif',color:'#D32F2F'}:{fontFamily:'"Geologica", sans-serif',color:'#2E7D32'}}>{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
function LinearProgressWithLabelToxic(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} color={props.value < 49?'success':'error'}/>
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary" sx={props.value > 49?{fontFamily:'"Geologica", sans-serif',color:'#D32F2F'}:{fontFamily:'"Geologica", sans-serif',color:'#2E7D32'}}>{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

export default function Agents() {
    const {t,i18n} = useTranslation();
    const [agents, setAgents] = useState([])
    const [type, setType] = useState('all');
    const [filteredAgents, setFilteredAgents] = useState([]);
    useEffect(()=>{
        const fetchData = async ()=>{
            try{
                const {data} = await axios.get(`https://base.calltracker.ai/api/agents/company/`,{headers:{
                    "Authorization":`Bearer ${secureLocalStorage.getItem("token")}`
                }})
                if(data){
                    setAgents(data.agents)
                }
            }catch(e){
                console.log(e)
            }
        }
        fetchData()
    },[])

    useEffect(() => {
        if (type === 'all') {
            setFilteredAgents(agents);
        } else {
            setFilteredAgents(agents.filter(call => call.team_type === type));
        }
    }, [type, agents]);
    const columns = useMemo(() => [
        {
            accessorKey: 'name',
            header: t('AgentsTableLogin'),
            size:190, 
            editable: false,     
            Cell: ({ row }) => (
                    <Link to={`/agent/${row.original.id}`} style={{color:"#266EF4"}}>{row.original.name}</Link>
            ),
        },
        {
            accessorKey: 'score',
            header: t('AgentsTableScores'),
            size:120,
            Cell:({row})=>(
                <div style={{ display:'flex',gap:'10px',fontWeight:'bold',alignItems:'center',justifyContent:'start' , backgroundColor:'rgba(0,167,111,0.16)',color:'#00A76F',padding:'4px 8px',borderRadius:'8px'}}>{row.original.score}<StarsRoundedIcon/></div>
            )
        },
        {
            accessorKey: 'team',
            header: t('AgentsTableTeam'),
            size:120,
        },
        {
            accessorKey: 'call_count',
            header: t('AgentsTableCalls'),
            size:110,
            Cell:({row})=>(
                <span style={{width:'80px',display:'flex' , alignItems:'center',justifyContent:'center',gap:'8px',backgroundColor:'#D4E2FD',color:'#266EF4',padding:'4px 8px',borderRadius:'8px',fontWeight:'bold'}}><PhoneRoundedIcon/>{row.original.call_count}</span>
            ),
        },
        {
            accessorKey: 'toxic_percentage',
            header: t('AgentsTableToxic'),
            grow: 1,     
            Cell: ({ row }) => (
                <div style={{width:'100%'}}><LinearProgressWithLabelToxic value={row.original.toxic_percentage?row.original.toxic_percentage:0}/></div>
            ),
        },
        {
            accessorKey: 'average_qa_percentage',
            header: t('AgentsTableScript'),
            grow: 1,     
            Cell: ({ row }) => (
                <div style={{width:'100%'}}><LinearProgressWithLabel value={row.original.average_qa_percentage?row.original.average_qa_percentage:0}/></div>
            ),
        },
    ], [i18n.language]);
    const table = useMaterialReactTable({
        columns,
        data : filteredAgents,
        initialState: { density: 'spacious', pagination: {pageSize:18} },
        muiTableContainerProps: {
          sx: (theme) => ({
            height:"76vh"
          })
        },
        defaultColumn: {
          maxSize: 400,
          minSize: 80,
          size: 160,
        }, 
        muiTableBodyProps: {
          sx: (theme) => ({
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
              {
                backgroundColor:'#fff',
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
    
              },
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
              {
                backgroundColor: '#fff',
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
    
              },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
              {
                backgroundColor: '#fff',
                
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
              },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
              {
                backgroundColor: '#fff',
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
              },
          }),
        },
        mrtTheme: (theme) => ({
          baseBackgroundColor: '#fff',
          draggingBorderColor: '#637381',
        }),
        muiExpandButtonProps:({row}) =>({
            sx:{
            color:'#000'
        
            }
        }),
        muiTableBodyCellProps: ({ column }) => ({
            sx: {
              backgroundColor:'#fff',
              fontFamily:"'Geologica', sans-serif",
              fontSize: '14px',
              color:'#000',
              '& .MuiTableCell-root':{
              }
            },
          }),
        muiTableProps: ({table})=>({
        sx:{
            backgroundColor:'grey',
            fontFamily:"'Geologica', sans-serif",
            fontSize: '14px',
        }
        }),
        muiTopToolbarProps: ({table})=>({
        sx:{
            backgroundColor:"#fff",
            fontFamily:"'Geologica', sans-serif",
            fontSize: '14px',
            borderTopLeftRadius:'5px',
            borderTopRightRadius:'5px',
            '& .MuiButtonBase-root':{
            color:'#637381'
            },
            '& .MuiSvgIcon-root':{
            color:'#000'

            },
            '& .MuiInputBase-root':{
            borderColor:'#000'

            },
            '& .MuiInputBase-input':{
            color:'#000',
            fontFamily:"'Geologica', sans-serif",
            }
        }
        }),
        muiColumnActionsButtonProps: ({row})=>({
        sx:{
            color:'#000'
        }
        }),
        muiTableHeadCellProps: {
        sx: {
            fontWeight: 'bold',
            fontFamily:"'Geologica', sans-serif",
            fontSize: '14px',
            backgroundColor:'#fff',
            color:'#637381',
            '& .MuiSvgIcon-root':{
            fill:'#000'
            },
            '& .MuiDivider-root':{
            },
            '& .MuiInputBase-input':{
            color:'#000',
            fontFamily:"'Geologica', sans-serif",
            }
        },
        },
          muiTableHeadProps:{
            sx:{
              '& .MuiTableCell-root':{
                color:'black'
              },
            }
          },
          muiPaginationProps: {
            showRowsPerPage: false,
            shape: 'rounded',
            sx:{
              '& .MuiButtonBase-root':{
                color:'#000'
              }
            }
          },
          muiBottomToolbarProps:{
            sx:{
              backgroundColor:'#fff',
              color:'#000'
    
            },
          },
          muiFilterTextFieldProps:{
            sx:{
              color:'#000'
            }
          },
          paginationDisplayMode: 'pages',
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        enableFullScreenToggle: false,
        localization: i18n.language === 'ru'?{
          actions: 'Действия',
          and: 'и',
          cancel: 'Отменить',
          changeFilterMode: 'Изменить режим фильтра',
          changeSearchMode: 'Изменить режим поиска',
          clearFilter: 'Очистить фильтр',
          clearSearch: 'Очистить поиск',
          clearSelection: 'Очистить выбор',
          clearSort: 'Очистить сортировку',
          clickToCopy: 'Нажмите, чтобы скопировать',
          copy: 'Копировать',
          columnActions: 'Действие колонки',
          copiedToClipboard: 'Скопировано в буфер обмена',
          collapse: 'Свернуть',
          collapseAll: 'Свернуть все',
          dropToGroupBy: 'Добавить в группу к {column}',
          edit: 'Редактировать',
          expand: 'Раскрыть',
          expandAll: 'Раскрыть все',
          filterArrIncludes: 'Включить',
          filterArrIncludesAll: 'Влючить все',
          filterArrIncludesSome: 'Включить',
          filterBetween: 'Между',
          filterBetweenInclusive: 'Между включительно',
          filterByColumn: 'Отфильтровать по {column}',
          filterContains: 'Содержит',
          filterEmpty: 'Пустой',
          filterEndsWith: 'Оканчивается',
          filterEquals: 'Равны',
          filterEqualsString: 'Равны',
          filterFuzzy: 'Физзи',
          filterGreaterThan: 'Больше чем',
          filterGreaterThanOrEqualTo: 'Больше или равно',
          filterInNumberRange: 'Между',
          filterIncludesString: 'Содержит',
          filterIncludesStringSensitive: 'Содержит (регистрозависимый)',
          filterLessThan: 'Меньше чем',
          filterLessThanOrEqualTo: 'Меньше или равно',
          filterMode: 'Режим фильтра: {filterType}',
          filterNotEmpty: 'Не пустое',
          filterNotEquals: 'Не равны',
          filterStartsWith: 'Начинается с',
          filterWeakEquals: 'Равны',
          filteringByColumn: 'Фильтр по {column} - {filterType} {filterValue}',
          goToFirstPage: 'Перейти на первую страницу',
          goToLastPage: 'Перейти на последнюю страницу',
          goToNextPage: 'Перейти на следующую страницу',
          goToPreviousPage: 'Перейти на предыдущую страницу',
          grab: 'Grab',
          groupByColumn: 'Сгруппировать по {column}',
          groupedBy: 'Сгруппировать',
          hideAll: 'Скрыть всё',
          hideColumn: 'Скрыть {column} колонку',
          max: 'Макс',
          min: 'Мин',
          move: 'Двигать',
          noRecordsToDisplay: <Loading/>,
          noResultsFound: 'Результатов не найдено',
          of: 'из',
          or: 'или',
          pin: 'Прикрепить',
          pinToLeft: 'Прикрепить слева',
          pinToRight: 'Прикрепить справа',
          resetColumnSize: 'Сбросить размер колонок',
          resetOrder: 'Сбросить порядок',
          rowActions: 'Действия строки',
          rowNumber: '#',
          rowNumbers: 'Количество строк',
          rowsPerPage: 'Строк на странице',
          save: 'Сохранить',
          search: 'Найти',
          selectedCountOfRowCountRowsSelected: '{selectedCount} из {rowCount} строк выбрано',
          select: 'Выбрать',
          showAll: 'Показать все',
          showAllColumns: 'Показать все колонки',
          showHideColumns: 'Показать/скрыть колонки',
          showHideFilters: 'Показать/скрыть фильтры',
          showHideSearch: 'Показать/скрыть поиск',
          sortByColumnAsc: 'Сортировать {column} по возрастанию',
          sortByColumnDesc: 'Сортировать {column} по убыванию',
          sortedByColumnAsc: 'Отсортирована по {column} возрастанию',
          sortedByColumnDesc: 'Отсортирована по {column} убыванию',
          thenBy: ', затем ',
          toggleDensity: 'Изменить плотность',
          toggleFullScreen: 'Включить полноэкранный режим',
          toggleSelectAll: 'Выбрать всё',
          toggleSelectRow: 'Переключить выбор строки',
          toggleVisibility: 'Изменить видимость',
          ungroupByColumn: 'Разгруппировать по {column}',
          unpin: 'Открепить',
          unpinAll: 'Открепить всё',
        }:{}
    
    });
  return (
    <div className={styles.page}>
        <CrmNavBar />
        <div className={styles.body}>
            <TopBar />
            <div className={styles.content}>
                    {secureLocalStorage.getItem('roleName') === 'BrandManager' || secureLocalStorage.getItem('roleName') === 'Couch'|| secureLocalStorage.getItem('roleName') === 'Admin'?
                    <div className={styles.types}>
                        <button style={type === 'all' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('all')}>{t('DashboardFilterAll')}</button>
                        <button style={type === 'RET' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('RET')}>RET</button>
                        <button style={type === 'SALE' ? { backgroundColor: '#266EF4', color: '#fff' } : {}} onClick={() => setType('SALE')}>SALE</button>
                    </div>:''}
                
                <MaterialReactTable table={table}  />
            </div>
        </div>
    </div>
  )
}
