import React, { useEffect, useState } from 'react'
import styles from './Scripts.module.scss'
import CrmNavBar from '../../components/CrmNavBar'
import TopBar from '../../components/TopBar'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip } from '@mui/material';
import { styled } from "@mui/material/styles";
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Loading from '../../components/Loading';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const BlueOnGreenTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #266EF4;
      background-color:#D4E2FD;
      font-size: 12px;
      font-family: "Geologica", sans-serif;
  `)
export default function Scripts() {
    const {t,i18n} = useTranslation();
    const [wordInputPositive, setWordInputPositive] = useState('');
    const [scriptInput, setScriptInput] = useState('');
    const [scripts, setScripts] = useState([]);
    const [wordInputNegative, setWordInputNegative] = useState('');
    const [wordsListPositive, setWordsListPositive] = useState([]);
    const [wordsListNegative, setWordsListNegative] = useState([]);
    const [wordsPositive, setWordsPositive] = useState([]);
    const [wordsNegative, setWordsNegative] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const newWords = wordInputPositive
            .split(/[\n,]/)
            .map(word => word.trim())
            .filter(word => word !== '')
            .map(word => ({ word, positive: true }));
        setWordsListPositive(newWords)
    }, [wordInputPositive]);

    useEffect(() => {
        const newWords = wordInputNegative
            .split(/[\n,]/)
            .map(word => word.trim())
            .filter(word => word !== '')
            .map(word => ({ word, positive: false }));
        setWordsListNegative(newWords)
    }, [wordInputNegative]);

    const handleRemoveWordPositive = (wordToRemove) => {
        const newWords = wordsListPositive.filter(word => word !== wordToRemove);
        setWordsListPositive(newWords);
        setWordInputPositive(newWords.map(el => el.word).join(', ') + (newWords.length > 0 ? ',' : ''));
    };

    const handleRemoveWordNegative = (wordToRemove) => {
        const newWords = wordsListNegative.filter(word => word !== wordToRemove);
        setWordsListNegative(newWords);
        setWordInputNegative(newWords.map(el => el.word).join(', ') + (newWords.length > 0 ? ',' : ''));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`https://base.calltracker.ai/api/company/my/`, {
                    headers: {
                        "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                })
                if (data) {
                    console.log(data)
                    setWordsPositive(data.check_words.filter(el => el.positive === true))
                    setWordsNegative(data.check_words.filter(el => el.positive === false))
                    setScripts(data.script.reverse())
                    setLoading(false)
                }
            } catch (e) {
                console.log(e)
            }
        }
        fetchData()
    }, [])
    const Add = async (type) => {
        try {
            const { data } = await axios.patch(`https://base.calltracker.ai/api/company/my/`, {
                check_words: wordsPositive.concat(wordsListPositive).concat(wordsListNegative).concat(wordsNegative),
            },
                {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
            if (data) {
                setWordsPositive(data.check_words.filter(el => el.positive === true))
                setWordsNegative(data.check_words.filter(el => el.positive === false))
                if (type === 'Positive') {
                    setWordInputPositive('')
                }
                if (type === 'Negative') {
                    setWordInputNegative('')
                }
                if (type === 'Script') {

                }
            }
        } catch (e) {
            console.log(e)
        }
    }
    const handleRemoveWordListNegative = async (wordToRemove) => {
        const newWords = wordsNegative.filter(word => word !== wordToRemove);
        try {
            const { data } = await axios.patch(`https://base.calltracker.ai/api/company/my/`, {
                check_words: wordsPositive.concat(wordsListPositive).concat(wordsListNegative).concat(newWords),
            },
                {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
            if (data) {
                setWordsPositive(data.check_words.filter(el => el.positive === true))
                setWordsNegative(data.check_words.filter(el => el.positive === false))
            }
        } catch (e) {
            console.log(e)
        }
    };
    const handleRemoveWordListPositive = async (wordToRemove) => {
        const newWords = wordsPositive.filter(word => word !== wordToRemove);
        try {
            const { data } = await axios.patch(`https://base.calltracker.ai/api/company/my/`, {
                check_words: newWords.concat(wordsListPositive).concat(wordsListNegative).concat(wordsNegative),
            },
                {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
            if (data) {
                setWordsPositive(data.check_words.filter(el => el.positive === true))
                setWordsNegative(data.check_words.filter(el => el.positive === false))
            }
        } catch (e) {
            console.log(e)
        }
    };
    const handleRemoveScript = async (scriptToRemove) => {
        const newScripts = scripts.filter(script => script !== scriptToRemove);
        try {
            const { data } = await axios.patch(`https://base.calltracker.ai/api/company/my/`, {
                script: newScripts,
            },
                {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
            if (data) {
                setScripts(data.script.reverse())
            }
        } catch (e) {
            console.log(e)
        }
    };
    const AddScript = async () => {
        if (!scriptInput) return
        try {
            let modifiedScriptInput = scriptInput.trim();
            if (modifiedScriptInput.length > 0) {
                const lastChar = modifiedScriptInput.slice(-1);
                if (lastChar === '.') {
                    modifiedScriptInput = modifiedScriptInput.slice(0, -1) + '?';
                } else if (lastChar === '!') {
                    modifiedScriptInput = modifiedScriptInput.slice(0, -1) + '?';
                } else if (lastChar !== '?') {
                    modifiedScriptInput += '?';
                }
            }
            const newScripts = [...scripts, modifiedScriptInput];
            const { data } = await axios.patch(`https://base.calltracker.ai/api/company/my/`, {
                script: newScripts,
            },
                {
                    headers: {
                        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
                    },
                })
            if (data) {
                setScripts(data.script.reverse())
                setScriptInput('')
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={styles.page}>
            <CrmNavBar />
            <div className={styles.body}>
                <TopBar />
                <div className={styles.content}>
                    {
                        secureLocalStorage.getItem('roleName') === 'Admin' ?
                            <div className={styles.addBlock}>
                                <div className={styles.addScript}>
                                    <h2>{t('ScriptsAddScript')}</h2>
                                    <input placeholder={t('ScriptsQuestion')} value={scriptInput} onChange={e => setScriptInput(e.target.value)} />
                                    <div className={styles.tutorialBlock}>
                                        <div className={styles.tutorial}>
                                            <WarningRoundedIcon sx={{ width: 28, height: 28 }} />
                                            <p>{t('ScriptsAddScriptTutorial')}</p>
                                        </div>
                                        <button className={styles.Add} onClick={AddScript}>{t('ScriptsAdd')}</button>
                                    </div>
                                </div>
                                <div className={styles.addWords}>
                                    <h2>{t('ScriptsPositiveWords')}</h2>
                                    <div className={styles.inputTutorial}>
                                        <textarea placeholder={t('ScriptsWords')} value={wordInputPositive} onChange={e => setWordInputPositive(e.target.value)} />
                                        <BlueOnGreenTooltip title={t('ScriptsAddWordsTutorial')} placement="left">
                                            <InfoRoundedIcon sx={{ width: 28, height: 28, color: '#266EF4', cursor: 'pointer' }} />
                                        </BlueOnGreenTooltip>
                                    </div>
                                    <div className={styles.wordsBlock}>
                                        <div className={styles.Words}>
                                            {wordsListPositive.map(el => <div className={styles.word}>
                                                <span>{el.word}</span>
                                                <ClearRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => handleRemoveWordPositive(el)} />
                                            </div>)}
                                        </div>
                                        <button className={styles.Add} onClick={() => Add('Positive')}>{t('ScriptsAdd')}</button>
                                    </div>
                                </div>
                                <div className={styles.addWords}>
                                    <h2>{t('ScriptsNegativeWords')}</h2>
                                    <div className={styles.inputTutorial}>
                                        <textarea placeholder={t('ScriptsWords')} value={wordInputNegative} onChange={e => setWordInputNegative(e.target.value)} />
                                        <BlueOnGreenTooltip title={t('ScriptsAddWordsTutorial')} placement="left">
                                            <InfoRoundedIcon sx={{ width: 28, height: 28, color: '#266EF4', cursor: 'pointer' }} />
                                        </BlueOnGreenTooltip>
                                    </div>
                                    <div className={styles.wordsBlock}>
                                        <div className={styles.Words}>
                                            {wordsListNegative.map(el => <div className={styles.word}>
                                                <span>{el.word}</span>
                                                <ClearRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => handleRemoveWordNegative(el)} />
                                            </div>)}
                                        </div>
                                        <button className={styles.Add} onClick={() => Add('Negative')}>{t('ScriptsAdd')}</button>
                                    </div>
                                </div>
                            </div> : ''
                    }
                    <div className={styles.mainBlock}>
                        <div className={styles.wordBlocks}>
                            <div className={styles.wordBlock}>
                                <h2>{t('ScriptsPositiveWords')}</h2>
                                {
                                    loading ? <Loading width={100} height={50} /> :
                                        <div className={styles.words}>
                                            {wordsPositive.map(el => <div key={el.word} className={styles.word}>
                                                <span>{el.word}</span>                    {
                                                    secureLocalStorage.getItem('roleName') === 'Admin' ?
                                                        <ClearRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => handleRemoveWordListPositive(el)} /> : ''
                                                }
                                            </div>)}
                                        </div>
                                }
                            </div>
                            <div className={styles.wordBlock}>
                                <h2>{t('ScriptsNegativeWords')}</h2>
                                {
                                    loading ? <Loading width={100} height={50} /> :
                                        <div className={styles.words}>
                                            {wordsNegative.map(el => <div key={el.word} className={styles.word}>
                                                <span>{el.word}</span>                    {
                                                    secureLocalStorage.getItem('roleName') === 'Admin' ?
                                                        <ClearRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => handleRemoveWordListNegative(el)} /> : ''
                                                }
                                            </div>)}
                                        </div>
                                }
                            </div>
                        </div>

                        {
                            loading ? <div className={styles.scriptBlocks} style={{ alignItems: 'center', justifyContent: 'center' }}><Loading width={400} height={200} /></div> :
                                <div className={styles.scriptBlocks}>
                                    {
                                        scripts?.map(el => <div className={styles.Script} key={el}>
                                            {el}
                                            <div className={styles.icons}>                    {
                                                secureLocalStorage.getItem('roleName') === 'Admin'?
                                                    <ClearRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => handleRemoveScript(el)} /> : ''
                                            }
                                            </div>
                                        </div>)
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
