import React, { useState } from 'react';
import styles from './Login.module.scss';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const {t} = useTranslation()
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [authError , setAuthError] = useState('')
  const validateForm = () => {
    let isValid = true;
    if (!login) {
      setLoginError('Введите логин');
      isValid = false;
    } else {
      setLoginError('');
    }

    if (!password) {
      setPasswordError('Введите пароль');
      isValid = false;
    } else {
      setPasswordError('');
    }

    return isValid;
  };

  const handleLogin = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const { data } = await axios.post('https://base.calltracker.ai/api/token/', { username: login, password });
      if (data) {
        secureLocalStorage.setItem('token', data.access);
        secureLocalStorage.setItem('brandId', data.company_id);
        secureLocalStorage.setItem('brandIds', data.company_ids);
        secureLocalStorage.setItem('isAuth', true);
        secureLocalStorage.setItem('role', data.is_staff);
        secureLocalStorage.setItem('roleName', data.role);
        window.location.href = '/dashboard';
      }
    } catch (e) {
      console.log(e.response.status);
      if(e.response.status === 401){
          setAuthError('Неправильный логин или пароль!')
      }
    }
  };

  return (
    <div className={styles.page}>
      <h1 className={styles.logo}>CALLTRACKER</h1>
      <div className={styles.loginForm}>
        <h1>{t('LoginSignIn')}</h1>
        <p >{t('LoginSignInP')}</p>
        <div className={styles.loginInputs}>
          <div style={{ width: '100%', position: 'relative' }}>
          <input
            placeholder={t('LoginLogin')}
            value={login}
            onChange={e => {setLogin(e.target.value);setLoginError('');setAuthError('')}}
          />
          {loginError && <div className={styles.error}>{loginError}</div>}
            
          </div>
          <div style={{ width: '100%', position: 'relative' }}>
            <input
              type={isVisible ? 'text' : 'password'}
              placeholder={t('LoginPassword')}
              value={password}
              onChange={e => {setPassword(e.target.value);setPasswordError('');setAuthError('')}}
            />
            {isVisible ? (
              <VisibilityOffRoundedIcon
                onClick={() => setIsVisible(!isVisible)}
                style={{ cursor: 'pointer', position: 'absolute', top: '18px', width: '30px', height: '30px', right: '40px' }}
              />
            ) : (
              <VisibilityRoundedIcon
                onClick={() => setIsVisible(!isVisible)}
                style={{ cursor: 'pointer', position: 'absolute', top: '18px', width: '30px', height: '30px', right: '40px' }}
              />
            )}
          {passwordError && <div className={styles.error}>{passwordError}</div>}

          </div>
        </div>
        <button onClick={handleLogin}>{t('LoginSignInButton')}</button>
        {authError && (
          <p style={{marginTop:'20px' , fontSize:'14px' , color:'red'}}>{authError}</p>
        )}
      </div>
    </div>
  );
}
