import React, { useEffect, useState } from 'react';
import styles from './Billing.module.scss';
import CrmNavBar from '../../components/CrmNavBar';
import TopBar from '../../components/TopBar';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Select from 'react-select';
import BillingChart from '../../components/BillingChart';
import moment from 'moment';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function Billing() {
    const {t} = useTranslation()
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [amount , setAmount] = useState('')
    const [amountError , setAmountError] = useState('')
    useEffect(() => {
        const FetchData = async () => {
            try {
                const company = await axios.get(`https://base.calltracker.ai/api/users/billing/`, {
                    headers: {
                      "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                });
                if (company) {
                    console.log(company);
                    let companiesData = company.data.companies;
                    let temp = [];
                    companiesData.forEach(el => {
                        temp.push({ value: el.id, label: el.name, weekly_spending: el.weekly_spending, billing: el.billing, billingHistory: el.billing_history_30_days });
                    });
                    setCompanies(temp);
                    
                    setSelectedCompany({ value: company.data.companies[0].id, 
                        label: company.data.companies[0].name, 
                        weekly_spending: company.data.companies[0].weekly_spending,
                        billing: company.data.companies[0].billing,
                        billingHistory: company.data.companies[0].billing_history_30_days
                    });

                } 
            } catch (e) {
                console.log(e);
            }
        };
        FetchData();
    }, []);

    console.log(companies.filter(el=> el.value === selectedCompany?.value))

    const Deposit = async ()=>{
    console.log(amountError)

        if(!amount){
            setAmountError('Введите сумму!')
            return
        }
        try{
            const {data} = await axios.post('https://base.calltracker.ai/api/company/billing/update/', {
                company_name:selectedCompany?.label,
                amount: amount,
            }, {
                headers: {
                    "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                }
            })
            if(data){
                const company = await axios.get(`https://base.calltracker.ai/api/users/billing/`, {
                    headers: {
                      "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
                    }
                });
                if (company) {
                    let companiesData = company.data.companies;
                    let temp = [];
                    companiesData.forEach(el => {
                        temp.push({ value: el.id, label: el.name, weekly_spending: el.weekly_spending, billing: el.billing, billingHistory: el.billing_history_30_days });
                    });
                    setCompanies(temp);
                    const selectCompany = company.data.companies.filter(el=> el.id === selectedCompany?.value);
                    setSelectedCompany({ value: selectCompany[0].id, 
                        label: selectCompany[0].name, 
                        weekly_spending: selectCompany[0].weekly_spending,
                        billing: selectCompany[0].billing,
                        billingHistory: selectCompany[0].billing_history_30_days
                    });
                }
            }
        }catch(e){
            console.log(e)
        }
    }
    if(secureLocalStorage.getItem('roleName') !== 'Admin'){
        return <Navigate to='/dashboard'/>
    }else
    return (
        <div className={styles.page}>
            <CrmNavBar />
            <div className={styles.body}>
                <TopBar />
                <div className={styles.content}>
                    <div className={styles.TopContent}>
                        <div className={styles.BalanceBlock}>
                            <div className={styles.topBlock}>
                                <h2>{t('BalanceBalance')}</h2>
                                {companies.length > 1 ? (
                                    <div className={styles.select}>
                                        <Select 
                                            options={companies} 
                                            value={selectedCompany} 
                                            placeholder={'Выберите бренд'} 
                                            styles={{
                                                control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%', marginRight: '2px', height: '35px' }),
                                                placeholder: (styles) => ({ ...styles, textAlign: 'start' }),
                                                option: (styles) => ({ ...styles, textAlign: 'start' }),
                                                singleValue: (styles) => ({ ...styles, textAlign: 'start' }),
                                                input: (styles) => ({ ...styles, textAlign: 'start', padding: '0px', margin: '0px', height: '32px' }),
                                            }}
                                            onChange={e => setSelectedCompany({ value: e.value, label: e.label, weekly_spending: e.weekly_spending, billing: e.billing, billingHistory: e.billingHistory })}
                                        />
                                    </div>
                                ) : ''}
                            </div>
                            <div className={styles.Balance}>
                                <h1 style={+selectedCompany?.billing > 0
                                    ? { color: '#118D57', backgroundColor: 'rgba(34,197,94,0.16)', padding: '6px 12px', borderRadius: '8px' }
                                    : { color: '#B71D18', backgroundColor: 'rgba(255, 86, 48,0.16)', padding: '6px 12px', borderRadius: '8px' }
                                }>
                                    ${selectedCompany?.billing}
                                </h1>
                            </div>
                            <div className={styles.chart}>
                                <BillingChart billingWeek={selectedCompany?.weekly_spending ? selectedCompany?.weekly_spending : []} />
                            </div>
                        </div>
                        <div className={styles.BalanceHistory}>
                            <h2>{t('BalanceBalanceHistory')}</h2>
                            <div className={styles.history}>
                                {selectedCompany?.billingHistory ? selectedCompany?.billingHistory.map(el => {
                                    return (
                                        <div className={styles.historyElem} key={el.timestamp}>
                                            <div className={styles.leftside}>
                                                <div className={styles.date}><AccessTimeRoundedIcon />{moment(el.timestamp).format('YYYY-MM-DD')}</div>
                                            </div>
                                            <div className={el.transaction_type === 'debit' ? styles.debit : styles.credit}>
                                                {el.transaction_type === 'debit' ? '-' : ''}{el.amount}$
                                            </div>
                                        </div>
                                    );
                                }) : null}
                            </div>
                        </div>
                    </div>
                    {secureLocalStorage.getItem('roleName') === 'Admin' ? (
                        <div className={styles.Panel}>
                            <h2>{t('BalanceBalanceReplenishment')}</h2>
                            <input type="text" placeholder={t('BalanceAmount')} value={amount} onChange={e=>setAmount(e.target.value)}/>
                            {amountError? <p style={{color:'red' , fontSize:'12px' , marginTop:'0px'}}>{amountError}</p>:''}
                            <div className={styles.deposit}>
                                <button onClick={Deposit}>{t('BalanceReplenishment')}</button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
