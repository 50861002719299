import React, { useEffect, useMemo, useState } from 'react';
import styles from './Agent.module.scss';
import CrmNavBar from '../../components/CrmNavBar';
import TopBar from '../../components/TopBar';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import moment from 'moment';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import {  Box,  CircularProgress,  Tooltip,  Typography,  circularProgressClasses } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import SentimentSatisfiedAltRoundedIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';
import SentimentVerySatisfiedRoundedIcon from '@mui/icons-material/SentimentVerySatisfiedRounded';
import CallEndRoundedIcon from '@mui/icons-material/CallEndRounded';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
 
function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} color={props.value < 50?'error':'success'}/>
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary" sx={props.value < 50?{fontFamily:'"Geologica", sans-serif',color:'#D32F2F'}:{fontFamily:'"Geologica", sans-serif',color:'#2E7D32'}}>{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
export default function Agent() {
  const {t,i18n} = useTranslation()
  const { id } = useParams();
  const [agent, setAgent] = useState({});
  const [calls, setCalls] = useState({});
  useEffect(() => {
    const fetchCall = async () => {
      try {
        const { data } = await axios.get(`https://base.calltracker.ai/api/agents/${id}/description/`, {
          headers: {
            "Authorization": `Bearer ${secureLocalStorage.getItem("token")}`
          }
        });
        if (data) {
            console.log()
          setAgent(data);
          setCalls(data.calls)
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchCall();
  }, [id]);
  function formatDuration(seconds) {
    const totalSeconds = Math.floor(seconds); // Округляем вниз до ближайшего целого числа
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}

function formatMood(mood) {
    if (mood === 'NEUTRAL') {
        return <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 8, borderRadius: '6px', padding: '4px 8px', color: '#266EF4', backgroundColor: '#D4E2FD' }}><SentimentSatisfiedAltRoundedIcon />{t('CallsTableSentimentNeutral')}</div>
    } else
        if (mood === 'POSITIVE') {
            return <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 8, borderRadius: '6px', padding: '4px 8px', color: '#00A76F', backgroundColor: '#C3DED5' }}><SentimentVerySatisfiedRoundedIcon />{t('CallsTableSentimentPositive')}</div>
        } else
            if (mood === 'NEGATIVE') {
                return <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 8, borderRadius: '6px', padding: '4px 8px', color: '#D32F2F', backgroundColor: '#EEAFAF' }}><SentimentDissatisfiedRoundedIcon />{t('CallsTableSentimentNegative')}</div>
            }
}
const columns = useMemo(() => [
    {
        accessorKey: 'id',
        header: 'ID',
        size: 150,
        flex: 1,
        Cell: ({ row }) => (
            <Link to={`/call/${row.original.id}`} style={{ color: '#266EF4' }}>{row.original.id.slice(0, 8)}...{row.original.id.slice(-8)}</Link>
        )

    },
    {
        accessorKey: 'length',
        header: t('CallsTableLength'),
        size: 100,
        flex: 1,
        Cell: ({ row }) => (
            <div>{formatDuration(row.original.length)} мин</div>
        )
    },
    {
        accessorKey: 'created_at',
        header: t('CallsTableCallDate'),
        size: 155,
        Cell: ({ row }) => (
            <div>{moment(row.original.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
        )
    },
    {
        accessorKey: 'updated_at',
        header: t('CallsTableProcessDate'),
        size: 165,

        Cell: ({ row }) => (
            <div>{row.original.processed ? moment(row.original.updated_at).format('YYYY-MM-DD HH:mm:ss') :
                <span style={{ padding: '4px 8px', backgroundColor: '#FFF1D6', color: '#B76E00', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px', borderRadius: '6px' }}><UpdateRoundedIcon />{t('CallsTableProcessing')}</span>}</div>
        )
    },
    {
        accessorKey: 'qa_percentage',
        header: t('CallsTableScript'),
        size: 135,
        Cell: ({ row }) => (
            <>

                {row.original.processed ?
                    <div style={{ width: '100%' }}><LinearProgressWithLabel value={row.original.qa_percentage ? row.original.qa_percentage : 0} /></div>
                    : <span style={{ width: '100%', padding: '4px 8px', backgroundColor: '#FFF1D6', color: '#B76E00', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px', borderRadius: '6px' }}>
                        <UpdateRoundedIcon />{t('CallsTableProcessing')}
                    </span>}
            </>

        )
    },
    {
        accessorKey: 'toxic',
        header: t('CallsTableToxic'),
        size: 145,
        flex: 1,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
            <Tooltip
                className={row.original.toxic ? styles.toxic : styles.notoxic}
                style={row.original.toxic
                    ? { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', padding: '6px 12px', borderRadius: '8px', backgroundColor: '#EEAFAF', color: 'rgb(183, 29, 24)', gap: '10px', fontSize: '12px' }
                    : { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', padding: '6px 12px', borderRadius: '8px', backgroundColor: 'rgba(0, 120, 103,0.16)', color: 'rgb(0, 120, 103)', gap: '10px', fontSize: '12px' }}
                arrow
                title={<span style={{ fontFamily: '"Geologica", sans-serif' }}>{row.original.toxic ? t('SoloCallToxicYesText') : t('SoloCallToxicText')}</span>}
                placement='bottom'>{row.original.toxic ? <ThumbDownAltRoundedIcon /> : <ThumbUpRoundedIcon />} {row.original.toxic ? t('CallsTableToxicty') : t('CallsTableNoToxicty')}
            </Tooltip>
        )
    },
    {
        accessorKey: 'sentiment',
        header: t('CallsTableSentiment'),
        size: 144,
        Cell: ({ row }) => (
            formatMood(row.original.sentiment)
        )
    },
    {
        accessorKey: 'stage',
        header: t('CallsTableStage'),
        size: 190,
        Cell: ({ row }) => (
            <>
                {row.original.processed ?
                    <span
                        style={{ width: '100%', padding: '4px 8px', backgroundColor: '#FFF1D6', color: '#B76E00', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px', borderRadius: '6px' }}>
                        <CallEndRoundedIcon />{row.original.stage ? row.original.stage.slice(0, 1).toUpperCase() + row.original.stage.slice(1).toLowerCase() : ''}
                    </span>
                    : <span style={{ width: '100%', padding: '4px 8px', backgroundColor: '#FFF1D6', color: '#B76E00', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px', borderRadius: '6px' }}>
                        <UpdateRoundedIcon />{t('CallsTableProcessing')}
                    </span>}
            </>
        )
    },
], []);
const table = useMaterialReactTable({
    columns,
    data : calls,
    initialState: { density: 'compact', pagination: {pageSize:18} },
    muiTableContainerProps: {
        sx: (theme) => ({
        height:"40vh"
        })
    },
    defaultColumn: {
        maxSize: 400,
        minSize: 80,
    }, 
    muiTableBodyProps: {
        sx: (theme) => ({
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
            {
                backgroundColor:'#fff',
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
    
            },
            '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
            {
                backgroundColor: '#fff',
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
    
            },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
            {
                backgroundColor: '#fff',
                
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
            },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
            {
                backgroundColor: '#fff',
                borderBottom:'1px dashed rgba(145, 158, 171, 0.3)',
            },
        }),
        },
        mrtTheme: (theme) => ({
        baseBackgroundColor: '#fff',
        draggingBorderColor: '#637381',
        }),
        muiExpandButtonProps:({row}) =>({
            sx:{
            color:'#000'
        
            }
        }),
        muiTableBodyCellProps: ({ column }) => ({
            sx: {
            backgroundColor:'#fff',
            fontFamily:"'Geologica', sans-serif",
            fontSize: '14px',
            color:'#000',
            '& .MuiTableCell-root':{
            }
            },
        }),
        muiTableProps: ({table})=>({
        sx:{
            backgroundColor:'grey',
            fontFamily:"'Geologica', sans-serif",
            fontSize: '14px',
        }
        }),
        muiTopToolbarProps: ({table})=>({
        sx:{
            backgroundColor:"#fff",
            fontFamily:"'Geologica', sans-serif",
            fontSize: '14px',
            borderTopLeftRadius:'5px',
            borderTopRightRadius:'5px',
            '& .MuiButtonBase-root':{
            color:'#637381'
            },
            '& .MuiSvgIcon-root':{
            color:'#266EF4'

            },
            '& .MuiInputBase-root':{
            borderColor:'#000'

            },
            '& .MuiInputBase-input':{
            color:'#000',
            fontFamily:"'Geologica', sans-serif",
            }
        }
        }),
        muiColumnActionsButtonProps: ({row})=>({
        sx:{
            color:'#000'
        }
        }),
        muiTableHeadCellProps: {
        sx: {
            fontWeight: 'bold',
            fontFamily:"'Geologica', sans-serif",
            fontSize: '14px',
            backgroundColor:'#fff',
            color:'#637381',
            '& .MuiSvgIcon-root':{
            fill:'#000'
            },
            '& .MuiDivider-root':{
            },
            '& .MuiInputBase-input':{
            color:'#000',
            fontFamily:"'Geologica', sans-serif",
            }
        },
        },
        muiTableHeadProps:{
            sx:{
            '& .MuiTableCell-root':{
                color:'black'
            },
            }
        },
        muiPaginationProps: {
            showRowsPerPage: false,
            shape: 'rounded',
            sx:{
            '& .MuiButtonBase-root':{
                color:'#000'
            }
            }
        },
        muiBottomToolbarProps:{
            sx:{
            backgroundColor:'#fff',
            color:'#000'
    
            },
        },
        muiFilterTextFieldProps:{
            sx:{
            color:'#000'
            }
        },
        paginationDisplayMode: 'pages',
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableFullScreenToggle: false,
    localization: i18n.language === 'ru'?{
        actions: 'Действия',
        and: 'и',
        cancel: 'Отменить',
        changeFilterMode: 'Изменить режим фильтра',
        changeSearchMode: 'Изменить режим поиска',
        clearFilter: 'Очистить фильтр',
        clearSearch: 'Очистить поиск',
        clearSelection: 'Очистить выбор',
        clearSort: 'Очистить сортировку',
        clickToCopy: 'Нажмите, чтобы скопировать',
        copy: 'Копировать',
        columnActions: 'Действие колонки',
        copiedToClipboard: 'Скопировано в буфер обмена',
        collapse: 'Свернуть',
        collapseAll: 'Свернуть все',
        dropToGroupBy: 'Добавить в группу к {column}',
        edit: 'Редактировать',
        expand: 'Раскрыть',
        expandAll: 'Раскрыть все',
        filterArrIncludes: 'Включить',
        filterArrIncludesAll: 'Влючить все',
        filterArrIncludesSome: 'Включить',
        filterBetween: 'Между',
        filterBetweenInclusive: 'Между включительно',
        filterByColumn: 'Отфильтровать по {column}',
        filterContains: 'Содержит',
        filterEmpty: 'Пустой',
        filterEndsWith: 'Оканчивается',
        filterEquals: 'Равны',
        filterEqualsString: 'Равны',
        filterFuzzy: 'Физзи',
        filterGreaterThan: 'Больше чем',
        filterGreaterThanOrEqualTo: 'Больше или равно',
        filterInNumberRange: 'Между',
        filterIncludesString: 'Содержит',
        filterIncludesStringSensitive: 'Содержит (регистрозависимый)',
        filterLessThan: 'Меньше чем',
        filterLessThanOrEqualTo: 'Меньше или равно',
        filterMode: 'Режим фильтра: {filterType}',
        filterNotEmpty: 'Не пустое',
        filterNotEquals: 'Не равны',
        filterStartsWith: 'Начинается с',
        filterWeakEquals: 'Равны',
        filteringByColumn: 'Фильтр по {column} - {filterType} {filterValue}',
        goToFirstPage: 'Перейти на первую страницу',
        goToLastPage: 'Перейти на последнюю страницу',
        goToNextPage: 'Перейти на следующую страницу',
        goToPreviousPage: 'Перейти на предыдущую страницу',
        grab: 'Grab',
        groupByColumn: 'Сгруппировать по {column}',
        groupedBy: 'Сгруппировать',
        hideAll: 'Скрыть всё',
        hideColumn: 'Скрыть {column} колонку',
        max: 'Макс',
        min: 'Мин',
        move: 'Двигать',
        noRecordsToDisplay: <Loading/>,
        noResultsFound: 'Результатов не найдено',
        of: 'из',
        or: 'или',
        pin: 'Прикрепить',
        pinToLeft: 'Прикрепить слева',
        pinToRight: 'Прикрепить справа',
        resetColumnSize: 'Сбросить размер колонок',
        resetOrder: 'Сбросить порядок',
        rowActions: 'Действия строки',
        rowNumber: '#',
        rowNumbers: 'Количество строк',
        rowsPerPage: 'Строк на странице',
        save: 'Сохранить',
        search: 'Найти',
        selectedCountOfRowCountRowsSelected: '{selectedCount} из {rowCount} строк выбрано',
        select: 'Выбрать',
        showAll: 'Показать все',
        showAllColumns: 'Показать все колонки',
        showHideColumns: 'Показать/скрыть колонки',
        showHideFilters: 'Показать/скрыть фильтры',
        showHideSearch: 'Показать/скрыть поиск',
        sortByColumnAsc: 'Сортировать {column} по возрастанию',
        sortByColumnDesc: 'Сортировать {column} по убыванию',
        sortedByColumnAsc: 'Отсортирована по {column} возрастанию',
        sortedByColumnDesc: 'Отсортирована по {column} убыванию',
        thenBy: ', затем ',
        toggleDensity: 'Изменить плотность',
        toggleFullScreen: 'Включить полноэкранный режим',
        toggleSelectAll: 'Выбрать всё',
        toggleSelectRow: 'Переключить выбор строки',
        toggleVisibility: 'Изменить видимость',
        ungroupByColumn: 'Разгруппировать по {column}',
        unpin: 'Открепить',
        unpinAll: 'Открепить всё',
    }:{
      
      noRecordsToDisplay: <Loading/>,
    }

});
  return (
    <div className={styles.page}>
      <CrmNavBar />
      <div className={styles.body}>
        <TopBar />
        <div className={styles.content}>
          <div className={styles.CallTop}>
            <div className={styles.CallId}>
              <span className={styles.Id}><SupportAgentIcon />{agent.name}</span>
            </div>
            <div className={styles.CallContent}>
              <div className={styles.ScriptPercentage}>
                <h3>{t('CallsTableScript')}</h3>
                <CircularProgress size='10rem' variant="determinate" thickness={2.6} value={agent.average_qa_percentage} sx={{
                  marginTop: '10px',
                  borderRadius: '50%',
                  boxShadow: `inset 0 0 0 ${2.6 / 44 * 10}rem ${'rgba(145, 158, 171, 0.16)'}`,
                  [`& .${circularProgressClasses.circle}`]: {
                    stroke: '#00A76F',
                    strokeLinecap: 'round',
                  },
                }} />
                <p className={styles.count}>{agent.average_qa_percentage? agent.average_qa_percentage.toFixed(0):0}%</p>
              </div>
              <div className={styles.ScriptPercentage}>
                <h3>{t('AgentsTableScores')}</h3>
                <CircularProgress size='10rem' variant="determinate" thickness={2.6} value={agent.score} sx={{
                  marginTop: '10px',
                  borderRadius: '50%',
                  boxShadow: `inset 0 0 0 ${2.6 / 44 * 10}rem ${'rgba(145, 158, 171, 0.16)'}`,
                  [`& .${circularProgressClasses.circle}`]: {
                    stroke: '#266EF4',
                    strokeLinecap: 'round',
                  },
                }} />
                <p className={styles.count}>{agent.score}</p>
              </div>
              <div className={styles.ScriptPercentage}>
                <h3>{t('AgentsTableToxic')}</h3>
                <CircularProgress size='10rem' variant="determinate" thickness={2.6} value={agent.toxic_percentage} sx={{
                  marginTop: '10px',
                  borderRadius: '50%',
                  boxShadow: `inset 0 0 0 ${2.6 / 44 * 10}rem ${'rgba(145, 158, 171, 0.16)'}`,
                  [`& .${circularProgressClasses.circle}`]: {
                    stroke: '#B76E00',
                    strokeLinecap: 'round',
                  },
                }} />
                <p className={styles.count}>{agent.toxic_percentage?agent.toxic_percentage.toFixed(0):0}%</p>
              </div>
            </div>
          </div>
          <div className={styles.AgentBottom}>
            
                <MaterialReactTable table={table}  />
          </div>
        </div>
      </div>
    </div>
  );
}
